import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum CloudflareIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
	NotUsed = 'not-used',
}



type CloudflareIconBuilderProps = {
	variant?: CloudflareIconVariants,
};

const CloudflareIconBuilder: IconBuilder<CloudflareIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = CloudflareIconVariants.Default,
		} = props;

		if (variant === CloudflareIconVariants.NotAvailable) {
			return (
				<g>
					<path
						d="M18.5411 11.6218c-.0718 0-.1435 0-.2153.0059-.0116.0007-.0229.0036-.0336.0085-.019.0067-.036.0186-.0497.0346-.0137.016-.0236.0355-.029.0567l-.3054 1.1697c-.1321.5028-.0825.9668.1382 1.3082.2031.3153.5414.4995.953.5215l1.6602.1099c.0235.0007.0466.0076.0672.0202.0201.0127.0375.03.0511.0508.0139.0227.0228.0488.026.076.0037.0285.0009.0576-.0084.0845-.0132.0423-.0373.0793-.0694.1065-.032.0272-.0705.0433-.1108.0465l-1.7259.1099c-.9362.0481-1.9457.8848-2.2993 1.9049l-.1245.3601c-.0051.015-.007.0313-.0053.0473.0017.016.0066.0313.0145.0448.0083.0133.0189.0245.0313.0329.013.0079.0273.0125.042.0136h5.9404c.0346.0002.0683-.012.0962-.0347.0274-.0232.0475-.0555.0573-.0921.1775-.7003.206-1.4365.0833-2.1511-.1226-.7145-.3931-1.3879-.7903-1.9673-.3972-.5795-.9102-1.0493-1.4989-1.3726-.5886-.3233-1.2369-.4913-1.8941-.4909l-.0008-.0043Z"
						fill="#C2C8CE"
						opacity=".6"
					/>
					<path
						d="M10.869 17.7348h5.0408c.0407-.0004.0802-.0153.1124-.0423.0322-.027.0555-.0648.0663-.1075l.1107-.4163c.1314-.4953.0825-.9532-.1382-1.2895-.2016-.3072-.5398-.4895-.9514-.5119l-2.3205-.0278-1.9201 2.3953Zm3.8893-10.38582c-.9619-.43195-2.0238-.53026-3.0431-.27505-.8546.21226-1.6458.66352-2.29774 1.31063-.65199.64712-1.14305 1.46846-1.42624 2.38554-.24226-.2005-.51784-.3462-.811-.4289-.29316-.0826-.59816-.1006-.89759-.0528-.29943.0478-.58743.1603-.84754.3313-.26011.1709-.48725.3968-.66844.6649-.25.3697-.40441.8078-.44669 1.2672-.02751.2981-.00761.5991.0588.8896-.85107.0279-1.65889.4213-2.25227 1.0969-.59337.6756-.92568 1.5803-.92649 2.5224.00062.1752.01236.3503.03512.5237.00518.0406.02354.0778.05164.1045.02809.0267.06402.0413.10107.0409H6.3738l8.3845-10.38082ZM13.3539 14.635l1.7233-.0244c.9323-.0474 1.9417-.8837 2.2953-1.9043l.4482-1.2943c.0178-.054.022-.1124.0122-.1689-.1089-.5372-.288-1.0536-.5312-1.53286L13.3539 14.635Z"
						fill="#ACB5C0"
						opacity=".6"
					/>
					<path
						clipRule="evenodd"
						d="M18.7199 5.13479c.232.18739.2682.52738.0808.75939L6.3601 21.4134c-.18739.232-.52738.2681-.75939.0807-.23201-.1874-.26818-.5273-.08079-.7593L17.9605 5.21557c.1874-.23201.5274-.26817.7594-.08078Z"
						fill="#BCC5CF"
						fillRule="evenodd"
					/>
				</g>
			);
		}

		if (variant === CloudflareIconVariants.NotUsed) {
			return (
				<g>
					<path
						d="M15.9462 16.646L16.057 16.2234C16.1883 15.7205 16.1395 15.2557 15.9188 14.9143C15.7156 14.5999 15.3773 14.4148 14.9657 14.3928L7.17656 14.2838C7.1523 14.2838 7.12843 14.2771 7.10707 14.2644C7.08576 14.2518 7.06745 14.2338 7.05362 14.212C7.03988 14.1899 7.03075 14.1648 7.02689 14.1384C7.02429 14.1113 7.02715 14.0839 7.03529 14.0581C7.0484 14.0158 7.07259 13.9787 7.10478 13.9516C7.13711 13.9246 7.17584 13.9084 7.21627 13.9052L15.0772 13.7953C16.0096 13.748 17.0192 12.9113 17.3727 11.8903L17.821 10.5956C17.8387 10.5415 17.843 10.4831 17.8332 10.4265C17.5889 9.2217 16.9957 8.13813 16.1439 7.34062C15.2921 6.54312 14.2283 6.07531 13.1142 6.00834C12.0001 5.94138 10.8968 6.27893 9.97193 6.96965C9.04709 7.66037 8.35138 8.66647 7.99061 9.83494C7.6091 9.51917 7.14852 9.34205 6.67229 9.32797C6.19606 9.31389 5.72759 9.46355 5.3314 9.75634C4.9352 10.0491 4.63075 10.4706 4.45998 10.9628C4.2892 11.4551 4.2605 11.9937 4.37781 12.5048C3.52666 12.5327 2.71878 12.9262 2.12535 13.6021C1.53192 14.2779 1.19958 15.183 1.19876 16.1254C1.19939 16.3007 1.21113 16.4758 1.23389 16.6494C1.23908 16.69 1.25743 16.7271 1.28553 16.7539C1.31363 16.7806 1.34956 16.7951 1.38662 16.7947H15.766C15.8067 16.7942 15.8461 16.7791 15.8783 16.7516C15.9105 16.7242 15.9338 16.6859 15.9447 16.6426L15.9462 16.646Z"
						fill="#ACB5C0"
					/>
					<path
						d="M18.5411 10.687C18.4693 10.687 18.3976 10.687 18.3258 10.693C18.3142 10.6937 18.3029 10.6965 18.2922 10.7014C18.2732 10.7081 18.2562 10.72 18.2425 10.7361C18.2288 10.752 18.2189 10.7715 18.2135 10.7927L17.9081 11.9624C17.776 12.4652 17.8256 12.9292 18.0463 13.2707C18.2494 13.5859 18.5877 13.7701 18.9993 13.7921L20.6595 13.902C20.683 13.9027 20.7061 13.9097 20.7267 13.9223C20.7468 13.9349 20.7642 13.9522 20.7778 13.973C20.7917 13.9958 20.8006 14.0218 20.8038 14.049C20.8076 14.0775 20.8047 14.1066 20.7954 14.1336C20.7822 14.1758 20.7581 14.2128 20.726 14.24C20.694 14.2672 20.6555 14.2834 20.6152 14.2865L18.8893 14.3964C17.9531 14.4446 16.9436 15.2813 16.59 16.3014L16.4655 16.6614C16.4604 16.6765 16.4585 16.6927 16.4602 16.7087C16.4619 16.7247 16.4668 16.74 16.4747 16.7535C16.483 16.7668 16.4936 16.778 16.506 16.7865C16.519 16.7943 16.5333 16.7989 16.548 16.8H22.4884C22.523 16.8002 22.5567 16.788 22.5846 16.7653C22.612 16.7421 22.6321 16.7099 22.6419 16.6732C22.8194 15.9729 22.8479 15.2367 22.7252 14.5222C22.6026 13.8076 22.3321 13.1342 21.9349 12.5548C21.5377 11.9753 21.0247 11.5055 20.436 11.1822C19.8474 10.8589 19.1991 10.6909 18.5419 10.6913L18.5411 10.687Z"
						fill="#C2C8CE"
					/>
				</g>
			);
		}

		return (
			<g>
				<path
					d="m15.9463 16.646.1107-.4226c.1313-.5029.0825-.9677-.1382-1.3091-.2032-.3144-.5415-.4995-.9531-.5215l-7.78914-.109c-.02426 0-.04813-.0067-.06949-.0194-.02131-.0126-.03962-.0306-.05345-.0524-.01373-.0221-.02287-.0472-.02673-.0736-.0026-.0271.00027-.0545.0084-.0803.01311-.0423.0373-.0794.06949-.1065.03233-.027.07106-.0432.1115-.0464l7.86092-.1099c.9324-.0473 1.942-.884 2.2955-1.905l.4483-1.2947c.0178-.0541.022-.1125.0122-.1691-.2443-1.2048-.8375-2.28837-1.6893-3.08588-.8518-.7975-1.9156-1.26531-3.0297-1.33228-1.1141-.06696-2.2174.27059-3.14227.96131-.92484.69072-1.62055 1.69682-1.98132 2.86529-.38151-.31577-.84209-.49289-1.31832-.50697-.47623-.01408-.9447.13558-1.34089.42837-.3962.29276-.70064.71426-.87142 1.20646-.17077.4923-.19948 1.0309-.08217 1.542-.85115.0279-1.65903.4214-2.25246 1.0973-.59343.6758-.92577 1.5809-.92659 2.5233.00063.1753.01237.3504.03513.524.00519.0406.02354.0777.05164.1045.0281.0267.06403.0412.10109.0408H15.766c.0407-.0005.0801-.0156.1123-.0431.0322-.0274.0555-.0657.0664-.109l.0016.0034Z"
					fill="#F6821F"
				/>
				<path
					d="M18.5411 10.687c-.0718 0-.1435 0-.2153.006-.0116.0007-.0229.0035-.0336.0084-.019.0067-.036.0186-.0497.0347-.0137.0159-.0236.0354-.029.0566l-.3054 1.1697c-.1321.5028-.0825.9668.1382 1.3083.2031.3152.5414.4994.953.5214l1.6602.1099c.0235.0007.0466.0077.0672.0203.0201.0126.0375.0299.0511.0507.0139.0228.0228.0488.026.076.0037.0285.0009.0576-.0084.0846-.0132.0422-.0373.0792-.0694.1064-.032.0272-.0705.0434-.1108.0465l-1.7259.1099c-.9362.0482-1.9457.8849-2.2993 1.905l-.1245.36c-.0051.0151-.007.0313-.0053.0473.0017.016.0066.0313.0145.0448.0083.0133.0189.0245.0313.033.013.0078.0273.0124.042.0135h5.9404c.0346.0002.0683-.012.0962-.0347.0274-.0232.0475-.0554.0573-.0921.1775-.7003.206-1.4365.0833-2.151-.1226-.7146-.3931-1.388-.7903-1.9674-.3972-.5795-.9102-1.0493-1.4989-1.3726-.5886-.3233-1.2369-.4913-1.8941-.4909l-.0008-.0043Z"
					fill="#FBAD41"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default CloudflareIconBuilder;

