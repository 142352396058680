import Immutable, {
	List,
	Map,
} from 'immutable';

import {
	CHANGE_URL_STATE,
} from '~/actions';
import {
	PEEK_COLUMN,
	STORE_HISTORICAL_CHANGES,
	UPDATE_FILTER,
} from '~/actions/historicalChanges';
import {
	CHANGES_IN_ANY_COLUMN,
	DEFAULT_FILTER,
} from '~/model/historicalChanges';

import decodeFromBase64 from '~/utilities/decodeFromBase64';



function unpackFilter(packedFilter) {
	const result = {
		filter: null,
	};
	let unpackedFilter;

	try {
		unpackedFilter = JSON.parse(
			decodeFromBase64(packedFilter),
		);
	} catch (error) {
		console.info('Failed to unpack filter', error);

		unpackedFilter = {};
	}

	if (unpackedFilter.filter) {
		result.filter = Immutable.fromJS(unpackedFilter.filter);
	}

	return result;
}



function createDefaultState() {
	return new Map({
		changesIn: List(),
		peekedByClick: false,
		peekedColumns: List(),
		showHiddenColumns: DEFAULT_FILTER.get('changes_in') === CHANGES_IN_ANY_COLUMN,
	});
}



export function changeTrackingPeekedColumns(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case CHANGE_URL_STATE: {
			const {
				urlState,
			} = action;

			if (!urlState.params.start_date && !urlState.params.end_date) {
				state = createDefaultState();
			}

			if (urlState.params.filter) {
				const unpackedFilter = unpackFilter(urlState.params.filter);

				if (unpackedFilter.filter) {
					const showHiddenColumns = unpackedFilter.filter.get('changes_in') === CHANGES_IN_ANY_COLUMN;
					if (showHiddenColumns) {
						state = state.set('showHiddenColumns', showHiddenColumns);
					}
				}
			}

			break;
		}

		case STORE_HISTORICAL_CHANGES: {
			const {
				changesIn,
			} = action;

			state = state.set('changesIn', changesIn);

			if (state.get('showHiddenColumns')) {
				const peekedColumns = state.get('peekedColumns');
				const newPeekedColumns = peekedColumns.concat(
					changesIn.filter((column) => peekedColumns.indexOf(column) < 0),
				);

				state = state.set('peekedColumns', newPeekedColumns);
				state = state.set('peekedByClick', false);
			} else if (state.get('showHiddenColumns') === false && !state.get('peekedByClick')) {
				state = state.set('peekedColumns', List());
			}

			break;
		}

		case PEEK_COLUMN: {
			const {
				columnName,
			} = action;

			const peekedColumns = state.get('peekedColumns');
			if (!peekedColumns.includes(columnName)) {
				state = state.set('peekedColumns', peekedColumns.push(columnName));
				state = state.set('peekedByClick', true);
			}

			break;
		}

		case UPDATE_FILTER: {
			const {
				filter,
			} = action;

			if (filter.get('changes_in') === CHANGES_IN_ANY_COLUMN) {
				state = state.set('showHiddenColumns', true);
			} else {
				state = state.set('showHiddenColumns', false);
			}

			break;
		}

	}

	return state;
}
