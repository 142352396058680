import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const OpenGraphIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="social-facebook"
					transform="translate(3.000000, 3.000000)"
				>
					<polygon
						id="Stroke-1353"
						points="44.6892187 44.6875 10.3142187 44.6875 10.3142187 10.3125 44.6892187 10.3125"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<path
						d="M29.2177187,44.6875 L29.2177187,22.8404688 C29.2177187,20.6679688 30.9794375,18.90625 33.1519375,18.90625 L36.0944375,18.90625"
						id="Stroke-1354"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M24.0635313,29.21875 L36.0947813,29.21875"
						id="Stroke-1355"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default OpenGraphIconBuilder;

