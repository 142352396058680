import {
	type Router,
	type SubscribeState,
} from 'router5';
import {
	type Unsubscribe,
} from 'router5/dist/types/base';

import createStore from '~/createStore';
import {
	assert,
	isFunction,
} from '~/utilities/typeCheck';



export enum UiInteraction {
	ColumnsSetsListOpen = 'columns_sets_list_open',
	IssuesCategoryOpen = 'issues_screen_open',
	PageDetailOpen = 'page_detail_open',
	PagesOverviewOpen = 'pages_overview_open',
}



function isRouteAccessed(
	subscribeState: SubscribeState,
	routeName: string,
) {
	return (
		subscribeState.route.name.startsWith(routeName) === true
		&& subscribeState.previousRoute.name.startsWith(routeName) === false
	);
}



export type UiInteractions = Record<UiInteraction, number>;

type Store = {
	interactions: UiInteractions,
	markInteraction: (interaction: UiInteraction) => void,
	setupRouterListener: (router: Router) => Unsubscribe,
};

const useUiInteractions = createStore<Store>(
	({ modify, get }) => ({
		interactions: {
			[UiInteraction.ColumnsSetsListOpen]: 0,
			[UiInteraction.IssuesCategoryOpen]: 0,
			[UiInteraction.PageDetailOpen]: 0,
			[UiInteraction.PagesOverviewOpen]: 0,
		},

		setupRouterListener: (router: Router) => {
			const markInteraction = get().markInteraction;

			const unsubscribe = router.subscribe((state: SubscribeState) => {
				if (isRouteAccessed(state, 'website.pages.detail')) {
					markInteraction(UiInteraction.PageDetailOpen);
				}

				if (isRouteAccessed(state, 'website.pages')) {
					markInteraction(UiInteraction.PagesOverviewOpen);
				}

				if (isRouteAccessed(state, 'website.issues.issueCategory')) {
					markInteraction(UiInteraction.IssuesCategoryOpen);
				}
			});

			assert(isFunction(unsubscribe), 'Router subscription should return an unsubscribe function');

			return unsubscribe;
		},

		markInteraction: (interaction: UiInteraction) => {
			modify((state) => {
				if (!state.interactions[interaction]) {
					state.interactions[interaction] = 0;
				}

				state.interactions[interaction] += 1;
			});
		},
	}),
);



export default useUiInteractions;
