import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum AWSCloudFrontIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
	NotUsed = 'not-used',
}



type AWSCloudFrontIconBuilderProps = {
	variant?: AWSCloudFrontIconVariants,
};

const AWSCloudFrontIconBuilder: IconBuilder<AWSCloudFrontIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = AWSCloudFrontIconVariants.Default,
		} = props;

		if (variant === AWSCloudFrontIconVariants.NotAvailable) {
			return (
				<g>
					<path
						d="M18.96 9.84L15.36 10.2L15.24 10.32L17.16 10.56L21.24 10.32L18.96 9.84Z"
						fill="#AFB6BC"
					/>
					<path
						d="M14.76 13.56L18.96 13.92L21.12 13.68H21.24L17.16 13.44L14.76 13.56Z"
						fill="#E4E7EA"
					/>
					<path
						d="M14.76 13.56L18.96 13.92V19.32L14.76 18.24V13.56Z"
						fill="#C3C9CF"
					/>
					<path
						d="M21.24 13.56L19.08 13.92V19.32L21.24 18.24V13.56Z"
						fill="#DADDE0"
					/>
					<path
						d="M9.23998 10.32L5.03998 9.96001L2.75998 10.32L6.83998 10.56L9.23998 10.32Z"
						fill="#AFB6BC"
					/>
					<path
						d="M2.75998 10.32L4.91998 9.95999H5.03998V4.67999L2.75998 5.75999V10.32Z"
						fill="#C3C9CF"
					/>
					<path
						d="M9.24001 10.32L5.04001 9.95999V4.67999L9.24001 5.75999V10.32Z"
						fill="#DADDE0"
					/>
					<path
						d="M16.44 14.28L12 14.88V22.68L16.44 20.52V14.28Z"
						fill="#DADDE0"
					/>
					<path
						d="M7.56 9.6L12 9V1.2L7.56 3.36V9.6Z"
						fill="#C3C9CF"
					/>
					<path
						d="M3.36 23.16C3.24 23.16 3.12 23.16 3 23.04C2.76 22.8 2.76 22.44 2.88 22.2L20.16 0.959992C20.4 0.719992 20.76 0.719992 21 0.839992C21.24 1.07999 21.24 1.43999 21.12 1.67999L3.84 22.92C3.72 23.04 3.6 23.16 3.36 23.16Z"
						fill="#BCC5CF"
					/>
					<path
						d="M16.44 4.19999V3.35999L12 1.07999V8.99999H12.48L16.44 4.19999Z"
						fill="#DADDE0"
					/>
					<path
						d="M8.76001 13.56L5.04001 13.92V18.24L8.76001 13.56Z"
						fill="#DADDE0"
					/>
					<path
						d="M12.48 8.99999L12 8.87999L7.56 9.59999L11.64 9.95999L12.48 8.99999Z"
						fill="#AFB6BC"
					/>
					<path
						d="M4.43998 18.96L5.03998 18.12V13.92L2.75998 13.56V18.12L4.43998 18.96Z"
						fill="#C3C9CF"
					/>
					<path
						d="M5.03998 13.92L8.63998 13.68L8.75998 13.56L6.83998 13.32L2.75998 13.56L2.87998 13.68L5.03998 13.92Z"
						fill="#E4E7EA"
					/>
					<path
						d="M15.36 10.32L18.96 9.96001V5.76001L15.36 10.32Z"
						fill="#C3C9CF"
					/>
					<path
						d="M19.68 4.92001L18.96 5.76001V9.96001L21.24 10.32V5.76001L19.68 4.92001Z"
						fill="#DADDE0"
					/>
					<path
						d="M12 14.88H11.64L7.56 19.8V20.52L12 22.68V15V14.88Z"
						fill="#C3C9CF"
					/>
					<path
						d="M11.52 14.88L12 15L16.32 14.28H16.44L12.36 13.92L11.52 14.88Z"
						fill="#E4E7EA"
					/>
				</g>
			);
		}

		if (variant === AWSCloudFrontIconVariants.NotUsed) {
			return (
				<g>
					<path
						d="M14.698 10.3662L18.9553 9.91212L21.1612 10.3701L21.1692 10.375L17.102 10.6176L14.6942 10.3701L14.698 10.3662Z"
						fill="#82868A"
					/>
					<path
						d="M14.6942 10.3702L18.9517 10.0376L18.9809 9.99495L18.9811 4.74227L18.9518 4.68222L14.6942 5.78944V10.3702Z"
						fill="#96A0AB"
					/>
					<path
						d="M21.1693 10.3751L18.9517 10.0376L18.9518 4.68222L21.1692 5.79167L21.1693 10.3751Z"
						fill="#B6C3D1"
					/>
					<path
						d="M14.6942 13.6297L14.7564 13.6733L18.9524 13.958L21.0933 13.6733L21.1692 13.6317L17.1021 13.3818L14.6942 13.6297Z"
						fill="#CED3D9"
					/>
					<path
						d="M14.6942 13.6296L18.9524 13.9464L18.9656 13.9641L18.9623 19.2814L18.9518 19.3091L14.6942 18.2107V13.6296Z"
						fill="#96A0AB"
					/>
					<path
						d="M21.1692 13.6317L18.9524 13.9465L18.9518 19.3092L21.1692 18.2083V13.6317Z"
						fill="#B6C3D1"
					/>
					<path
						d="M9.16868 10.3621L4.95971 10.0015L2.77404 10.3644L2.76016 10.3752L6.82725 10.6178L9.19126 10.3751L9.16868 10.3621V10.3621Z"
						fill="#82868A"
					/>
					<path
						d="M2.76016 10.3751L4.95756 10.0534L5.0222 10.0076V4.7437L4.95756 4.68222L2.76016 5.79188V10.3751Z"
						fill="#96A0AB"
					/>
					<path
						d="M9.19128 10.375L4.95758 10.0534V4.68222L9.1915 5.78944L9.19128 10.375Z"
						fill="#B6C3D1"
					/>
					<path
						d="M9.19125 13.6317L9.0941 13.7025L4.95755 14.0126L2.8302 13.7025L2.76001 13.6317L6.82724 13.3818L9.19125 13.6317Z"
						fill="#CED3D9"
					/>
					<path
						d="M2.76001 13.6316L4.95747 13.9437L5.01335 14.0122L5.01932 19.2138L4.95755 19.3092L2.76015 18.2083L2.76001 13.6316Z"
						fill="#96A0AB"
					/>
					<path
						d="M9.1913 13.6316L4.95752 13.9437L4.95759 19.3092L9.1913 18.2107V13.6316Z"
						fill="#B6C3D1"
					/>
					<path
						d="M16.3587 14.3161L11.9647 13.8827L7.52692 14.3161L7.58948 14.369L11.9431 15.0606L16.2961 14.369L16.3587 14.3161Z"
						fill="#CED3D9"
					/>
					<path
						d="M7.52692 14.3161L11.9431 14.9632L11.9879 15.0231L11.9935 22.7147L11.9431 22.7999L7.52692 20.5918V14.3161Z"
						fill="#96A0AB"
					/>
					<path
						d="M16.3587 14.3161L11.9431 14.9632V22.7999L16.3588 20.5918L16.3587 14.3161Z"
						fill="#B6C3D1"
					/>
					<path
						d="M11.9647 10.1121L7.52692 9.68368L7.53835 9.67714L11.9428 8.99384L16.3454 9.67872L16.3588 9.68368L11.9647 10.1121Z"
						fill="#82868A"
					/>
					<path
						d="M7.52692 9.68373L11.9431 9.0402L11.9642 9.02092L11.9544 1.21186L11.9431 1.2L7.52692 3.40832V9.68373Z"
						fill="#96A0AB"
					/>
					<path
						d="M16.3588 9.68373L11.9431 9.0402V1.2L16.3588 3.40832V9.68373Z"
						fill="#B6C3D1"
					/>
				</g>
			);
		}

		return (
			<g>
				<path
					d="M14.698 10.3662L18.9553 9.91217L21.1613 10.3702L21.1693 10.3751L17.1021 10.6177L14.6942 10.3702L14.698 10.3662Z"
					fill="#5E1F18"
				/>
				<path
					d="M14.6943 10.3702L18.9517 10.0376L18.981 9.99494L18.9811 4.74225L18.9519 4.68221L14.6943 5.78942V10.3702Z"
					fill="#8C3123"
				/>
				<path
					d="M21.1693 10.3751L18.9517 10.0376L18.9519 4.68221L21.1693 5.79165L21.1693 10.3751Z"
					fill="#E05243"
				/>
				<path
					d="M14.6943 13.6298L14.7565 13.6733L18.9524 13.958L21.0934 13.6733L21.1692 13.6317L17.1022 13.3818L14.6943 13.6298Z"
					fill="#F2B0A9"
				/>
				<path
					d="M14.6943 13.6297L18.9524 13.9464L18.9656 13.9641L18.9624 19.2814L18.9519 19.3091L14.6943 18.2107V13.6297Z"
					fill="#8C3123"
				/>
				<path
					d="M21.1692 13.6317L18.9524 13.9465L18.9518 19.3092L21.1692 18.2083V13.6317Z"
					fill="#E05243"
				/>
				<path
					d="M9.16869 10.3621L4.95972 10.0015L2.77404 10.3644L2.76016 10.3752L6.82725 10.6179L9.19127 10.3751L9.16869 10.3621V10.3621Z"
					fill="#5E1F18"
				/>
				<path
					d="M2.76016 10.3751L4.95756 10.0534L5.02221 10.0076V4.74369L4.95756 4.68221L2.76016 5.79187V10.3751Z"
					fill="#8C3123"
				/>
				<path
					d="M9.19129 10.375L4.95758 10.0534V4.68219L9.19151 5.78941L9.19129 10.375Z"
					fill="#E05243"
				/>
				<path
					d="M9.19126 13.6317L9.09411 13.7025L4.95755 14.0127L2.8302 13.7025L2.76001 13.6317L6.82725 13.3818L9.19126 13.6317Z"
					fill="#F2B0A9"
				/>
				<path
					d="M2.76001 13.6317L4.95748 13.9438L5.01335 14.0123L5.01932 19.2138L4.95755 19.3093L2.76015 18.2084L2.76001 13.6317Z"
					fill="#8C3123"
				/>
				<path
					d="M9.19131 13.6317L4.95752 13.9438L4.95759 19.3093L9.19131 18.2108V13.6317Z"
					fill="#E05243"
				/>
				<path
					d="M16.3587 14.3161L11.9647 13.8828L7.52695 14.3162L7.58951 14.369L11.9431 15.0606L16.2961 14.369L16.3587 14.3161Z"
					fill="#F2B0A9"
				/>
				<path
					d="M7.52695 14.3162L11.9431 14.9633L11.9879 15.0232L11.9936 22.7147L11.9431 22.8L7.52695 20.5919V14.3162Z"
					fill="#8C3123"
				/>
				<path
					d="M16.3588 14.3161L11.9431 14.9633V22.8L16.3588 20.5919L16.3588 14.3161Z"
					fill="#E05243"
				/>
				<path
					d="M11.9647 10.1121L7.52695 9.6837L7.53838 9.67715L11.9429 8.99385L16.3454 9.67873L16.3588 9.6837L11.9647 10.1121Z"
					fill="#5E1F18"
				/>
				<path
					d="M7.52695 9.68374L11.9431 9.04021L11.9642 9.02094L11.9545 1.21186L11.9431 1.2L7.52695 3.40832V9.68374Z"
					fill="#8C3123"
				/>
				<path
					d="M16.3588 9.68374L11.9431 9.04021V1.2L16.3588 3.40832V9.68374Z"
					fill="#E05243"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AWSCloudFrontIconBuilder;

