import React from 'react';

import type GraphQL from '~/types/graphql';

import useInterfaceHint from './useInterfaceHint';
import usePendoContext from './usePendoContext';

import {
	type PendoGuide,
} from '~/model/pendoGuides';



type Options = {
	guide: PendoGuide,
	hint: GraphQL.InterfaceHint,
};

function usePendoGuideInterfaceHint(options: Options) {
	const {
		guide,
		hint,
	} = options;

	const pendo = usePendoContext();

	const interfaceHint = useInterfaceHint({
		conditionsAlready: [],
		conditionsNotAnymore: [],
		name: hint,
	});


	const showHint = React.useCallback(
		() => {
			interfaceHint.display(() => {
				pendo.showGuide(guide);
				interfaceHint.markOpened();
			});
		},
		[
			guide,
			interfaceHint,
			pendo,
		],
	);

	return React.useMemo(
		() => ({
			showHint,
		}),
		[
			showHint,
		],
	);
}



export default usePendoGuideInterfaceHint;
