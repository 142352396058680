import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum SlackIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type SlackIconBuilderProps = {
	variant?: SlackIconVariants,
};

const SlackIconBuilder: IconBuilder<SlackIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = SlackIconVariants.Active,
		} = props;

		if (variant === SlackIconVariants.Inactive) {
			return (
				<g
					clipRule="evenodd"
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<path
						d="M6.78182 15.3093C6.78182 16.3501 5.93166 17.2002 4.89094 17.2002C3.85022 17.2002 3.00006 16.3501 3.00006 15.3093C3.00006 14.2686 3.85022 13.4185 4.89094 13.4185H6.78182V15.3093Z"
						fill="#96A4B4"
					/>
					<path
						d="M7.73465 15.3094C7.73465 14.2687 8.58481 13.4185 9.62553 13.4185C10.6662 13.4185 11.5164 14.2687 11.5164 15.3094V20.0439C11.5164 21.0846 10.6662 21.9348 9.62553 21.9348C8.58481 21.9348 7.73465 21.0846 7.73465 20.0439V15.3094Z"
						fill="#96A4B4"
					/>
					<path
						d="M9.6255 7.71657C8.58478 7.71657 7.73462 6.86641 7.73462 5.82569C7.73462 4.78498 8.58478 3.93481 9.6255 3.93481C10.6662 3.93481 11.5164 4.78498 11.5164 5.82569V7.71657H9.6255Z"
						fill="#BEC5CB"
					/>
					<path
						d="M9.62547 8.66935C10.6662 8.66935 11.5163 9.51951 11.5163 10.5602C11.5163 11.6009 10.6662 12.4511 9.62547 12.4511H4.89094C3.85022 12.4511 3.00006 11.6009 3.00006 10.5602C3.00006 9.51951 3.85022 8.66935 4.89094 8.66935H9.62547Z"
						fill="#BEC5CB"
					/>
					<path
						d="M17.2183 10.5602C17.2183 9.51951 18.0685 8.66935 19.1092 8.66935C20.1499 8.66935 21.0001 9.51951 21.0001 10.5602C21.0001 11.6009 20.1499 12.4511 19.1092 12.4511H17.2183V10.5602Z"
						fill="#ACB5C0"
					/>
					<path
						d="M16.2656 10.5602C16.2656 11.6009 15.4154 12.4511 14.3747 12.4511C13.334 12.4511 12.4838 11.6009 12.4838 10.5602V5.82569C12.4838 4.78498 13.334 3.93481 14.3747 3.93481C15.4154 3.93481 16.2656 4.78498 16.2656 5.82569V10.5602Z"
						fill="#ACB5C0"
					/>
					<path
						d="M14.3747 18.1531C15.4155 18.1531 16.2656 19.0033 16.2656 20.044C16.2656 21.0847 15.4155 21.9349 14.3747 21.9349C13.334 21.9349 12.4839 21.0847 12.4839 20.044V18.1531H14.3747Z"
						fill="#B4BEC7"
					/>
					<path
						d="M14.3747 17.2002C13.334 17.2002 12.4838 16.3501 12.4838 15.3093C12.4838 14.2686 13.334 13.4185 14.3747 13.4185H19.1092C20.1499 13.4185 21.0001 14.2686 21.0001 15.3093C21.0001 16.3501 20.1499 17.2002 19.1092 17.2002H14.3747Z"
						fill="#B4BEC7"
					/>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<path
					clipRule="evenodd"
					d="M6.78176 14.3745C6.78176 15.4152 5.9316 16.2654 4.89088 16.2654C3.85016 16.2654 3 15.4152 3 14.3745C3 13.3338 3.85016 12.4836 4.89088 12.4836H6.78176V14.3745Z"
					fill="#E01E5A"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M7.7345 14.3745C7.7345 13.3338 8.58466 12.4836 9.62538 12.4836C10.6661 12.4836 11.5163 13.3338 11.5163 14.3745V19.109C11.5163 20.1498 10.6661 20.9999 9.62538 20.9999C8.58466 20.9999 7.7345 20.1498 7.7345 19.109V14.3745Z"
					fill="#E01E5A"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M9.62538 6.78176C8.58466 6.78176 7.7345 5.9316 7.7345 4.89088C7.7345 3.85016 8.58466 3 9.62538 3C10.6661 3 11.5163 3.85016 11.5163 4.89088V6.78176H9.62538Z"
					fill="#36C5F0"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M9.62541 7.73456C10.6661 7.73456 11.5163 8.58472 11.5163 9.62544C11.5163 10.6662 10.6661 11.5163 9.62541 11.5163H4.89088C3.85016 11.5163 3 10.6662 3 9.62544C3 8.58472 3.85016 7.73456 4.89088 7.73456H9.62541Z"
					fill="#36C5F0"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M17.2183 9.62544C17.2183 8.58472 18.0684 7.73456 19.1091 7.73456C20.1499 7.73456 21 8.58472 21 9.62544C21 10.6662 20.1499 11.5163 19.1091 11.5163H17.2183V9.62544Z"
					fill="#2EB67D"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M16.2655 9.62541C16.2655 10.6661 15.4154 11.5163 14.3746 11.5163C13.3339 11.5163 12.4838 10.6661 12.4838 9.62541V4.89088C12.4838 3.85016 13.3339 3 14.3746 3C15.4154 3 16.2655 3.85016 16.2655 4.89088V9.62541Z"
					fill="#2EB67D"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M14.3746 17.2183C15.4154 17.2183 16.2655 18.0684 16.2655 19.1091C16.2655 20.1499 15.4154 21 14.3746 21C13.3339 21 12.4838 20.1499 12.4838 19.1091V17.2183H14.3746Z"
					fill="#ECB22E"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M14.3746 16.2654C13.3339 16.2654 12.4838 15.4152 12.4838 14.3745C12.4838 13.3338 13.3339 12.4836 14.3746 12.4836H19.1092C20.1499 12.4836 21.0001 13.3338 21.0001 14.3745C21.0001 15.4152 20.1499 16.2654 19.1092 16.2654H14.3746Z"
					fill="#ECB22E"
					fillRule="evenodd"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default SlackIconBuilder;

