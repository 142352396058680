import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageSize,
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import InternalLink from '~/components/patterns/links/InternalLink';
import LighthouseThresholdsModal from '~/components/atoms/modals/LighthouseThresholdsModal';
import OnPageRequestBlockingModal from '~/components/app/OnPageRequestBlockingModal';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import VerifyWebsiteLink from '~/components/app/VerifyWebsiteLink';

import useAccountDefaultLighthouseThresholds from '~/hooks/useAccountDefaultLighthouseThresholds';
import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useModals from '~/hooks/useModals';
import useOnPageRequestBlocking from '~/hooks/useOnPageRequestBlocking';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';
import useWebsiteIsVerified from '~/hooks/useWebsiteIsVerified';
import useWebsiteLighthouseThresholds from '~/hooks/useWebsiteLighthouseThresholds';

import {
	useUpdateWebsiteRenderingSettingsMutation,
} from './JavascriptRenderingAndLighthouseForm.gql';

import {
	EXTRACTION_SOURCE_DOM,
} from '~/model/customElements';

import {
	compareLighthouseThresholds,
} from '~/model/webVitals';



const messages = defineMessages({
	customElementInUse: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.customElementInUse',
	},
	disabled: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.disabled',
	},
	enabled: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.enabled',
	},
	isLighthouseMonitored: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.lighthouseMonitoring',
	},
	javascriptRendering: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.javascriptRendering',
	},
	javascriptRenderingDescription: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.javascriptRendering.description',
	},
	lighthouseThresholdsConfigured: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.configured',
	},
	lighthouseThresholdsEdit: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.edit',
	},
	lighthouseThresholdsHint: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.hint',
	},
	lighthouseThresholdsLabel: {
		id: 'ui.websites.form.monitoring.lighthouseThresholds.label',
	},
	needsVerification: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.needsVerification',
	},
	onPageRequestBlockingConfigured: {
		id: 'ui.websites.form.monitoring.onPageRequestBlocking.configured',
	},
	onPageRequestBlockingEdit: {
		id: 'ui.websites.form.monitoring.onPageRequestBlocking.edit',
	},
	onPageRequestBlockingHint: {
		id: 'ui.websites.form.monitoring.onPageRequestBlocking.hint',
	},
	onPageRequestBlockingLabel: {
		id: 'ui.websites.form.monitoring.onPageRequestBlocking.label',
	},
	onPageRequestBlockingWarningDescription: {
		id: 'ui.websites.form.monitoring.onPageRequestBlocking.warningDescription',
	},
	onPageRequestBlockingWarningTitle: {
		id: 'ui.general.caution',
	},
	title: {
		id: 'ui.websites.form.monitoring.javascriptRenderingAndLighthouse.title',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const JavascriptRenderingAndLighthouseForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const accountId = useAccountId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const defaultLighthouseThresholds = useAccountDefaultLighthouseThresholds(accountId);
	const isDomTracked = useWebsiteIsDomTracked(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);
	const lighthouseThresholds = useWebsiteLighthouseThresholds(websiteId);
	const modals = useModals();
	const onPageRequestBlocking = useOnPageRequestBlocking(websiteId);
	const websiteIsVerified = useWebsiteIsVerified(websiteId);

	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const isRenderingFeatureEnabled = accountEnabledFeatures?.includes(GraphQL.AccountFeature.Rendering);
	const isLighthouseMonitoringFeatureEnabled = accountEnabledFeatures?.includes(GraphQL.AccountFeature.LighthouseMonitoring);

	const [updateWebsiteRenderingSettings] = useUpdateWebsiteRenderingSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteRenderingSettings({
				variables: {
					isDomTracked: values.isDomTracked,
					isLighthouseMonitored: values.isLighthouseMonitored,
					websiteId,
				},
			});
		},
		[
			updateWebsiteRenderingSettings,
			websiteId,
		],
	);

	const openOnPageRequestBlockingModal = React.useCallback(
		() => {
			modals.openModal(
				() => (
					<OnPageRequestBlockingModal />
				),
			);
		},
		[
			modals,
		],
	);

	const openLighthouseThresholdsModal = React.useCallback(
		() => {
			if (lighthouseThresholds === null) {
				return;
			}

			modals.openModal(
				() => (
					<LighthouseThresholdsModal
						accountId={accountId}
						websiteId={websiteId}
					/>
				),
			);
		},
		[
			accountId,
			lighthouseThresholds,
			modals,
			websiteId,
		],
	);

	const isAllowedToEdit = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageRendering,
	);

	const isFormReady = (
		isDomTracked !== null
		&& isLighthouseMonitored !== null
	);

	const isReadOnly = (
		!isRenderingFeatureEnabled
		&& !isLighthouseMonitoringFeatureEnabled
		&& !isLighthouseMonitored
		&& !isDomTracked
	);

	const needsVerification = (
		websiteIsVerified === false
		&& isDomTracked === false
		&& isLighthouseMonitored === false
	);

	const usesDefaultLighthouseThresholds = lighthouseThresholds && defaultLighthouseThresholds
		? compareLighthouseThresholds(defaultLighthouseThresholds, lighthouseThresholds)
		: true;

	const validations = React.useMemo(
		() => {
			return {
				isDomTracked: [
					{
						message: (
							<FormattedMessage
								{...messages.customElementInUse}
								values={{
									link_cee: (chunks) => (
										<InternalLink
											routeName="website.detail.customElements"
											routeParams={{
												websiteId,
											}}
										>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						),
						field: 'isDomTracked',
						rule: ({ values, name }) => {
							const nextIsDomTracked = values[name];

							if (isDomTracked && !nextIsDomTracked) {
								const hasCustomElementsWithDOMSource = customElementDefinitions
									.listAll()
									.filter(
										(customElement) => customElement.extraction.source === EXTRACTION_SOURCE_DOM,
									)
									.length > 0;

								if (hasCustomElementsWithDOMSource) {
									return false;
								}
							}

							return true;
						},
					},
				],
			};
		},
		[
			customElementDefinitions,
			isDomTracked,
			websiteId,
		],
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToEdit}
			isReadOnly={isReadOnly}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<PremiumFeatureSituation
						featureName={GraphQL.AccountFeature.Rendering}
						hideIfUnattainable={false}
						style={PremiumFeatureSituationStyle.Ribbon}
					>
						{({ isFeatureEnabled, premiumAnnotation }) => (
							<DisabledContent
								disabledContent={!isFeatureEnabled}
								disabledOverlay={!isFeatureEnabled && premiumAnnotation}
							>
								<FormRow
									description={(
										<Copy
											{...messages.javascriptRenderingDescription}
											values={{
												trackDom: isDomTracked ? 'yes' : 'no',
											}}
										/>
									)}
									htmlFor="isDomTracked"
									label={(
										<FormattedMessage {...messages.javascriptRendering} />
									)}
								>
									<StaticText>
										{isDomTracked ? (
											<FormattedMessage {...messages.enabled} />
										) : (
											<FormattedMessage {...messages.disabled} />
										)}
									</StaticText>
								</FormRow>
							</DisabledContent>
						)}
					</PremiumFeatureSituation>

					<PremiumFeatureSituation
						featureName={GraphQL.AccountFeature.LighthouseMonitoring}
						hideIfUnattainable={false}
						style={PremiumFeatureSituationStyle.Ribbon}
					>
						{({ isFeatureEnabled, premiumAnnotation }) => (
							<DisabledContent
								disabledContent={!isFeatureEnabled}
								disabledOverlay={!isFeatureEnabled && premiumAnnotation}
							>
								<FormRow
									htmlFor="isLighthouseMonitored"
									label={(
										<FormattedMessage {...messages.isLighthouseMonitored} />
									)}
								>
									<StaticText>
										{isLighthouseMonitored ? (
											<FormattedMessage {...messages.enabled} />
										) : (
											<FormattedMessage {...messages.disabled} />
										)}
									</StaticText>
								</FormRow>
							</DisabledContent>
						)}
					</PremiumFeatureSituation>

					{isLighthouseMonitored && (
						<FormRow
							label={(
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<FormattedMessage {...messages.lighthouseThresholdsHint} />
											)}
										/>
									)}
								>
									<Copy {...messages.lighthouseThresholdsLabel} />
								</AttachedElement>
							)}
						>
							<StaticText>
								{lighthouseThresholds ? (
									<FormattedMessage
										{...messages.lighthouseThresholdsConfigured}
										values={{
											usesDefaultThresholds: usesDefaultLighthouseThresholds ? 'yes' : 'no',
										}}
									/>
								) : (
									<SquareSkeleton
										maxWidth={80}
									/>
								)}
							</StaticText>
						</FormRow>
					)}

					{(isDomTracked || isLighthouseMonitored) && (
						<FormRow
							label={(
								<AttachedElement
									element={(
										<HelpHint
											message={(
												<FormattedMessage {...messages.onPageRequestBlockingHint} />
											)}
										/>
									)}
								>
									<FormattedMessage {...messages.onPageRequestBlockingLabel} />
								</AttachedElement>
							)}
						>
							<StaticText>
								{onPageRequestBlocking ? (
									<FormattedMessage
										{...messages.onPageRequestBlockingConfigured}
										values={{
											count__rules: onPageRequestBlocking.rules.length,
											mode: onPageRequestBlocking.mode,
										}}
									/>
								) : (
									<SquareSkeleton
										maxWidth={80}
									/>
								)}
							</StaticText>
						</FormRow>
					)}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{isFormReady && (
					<Form
						defaultValues={{
							isDomTracked,
							isLighthouseMonitored,
						}}
						onSuccess={handleSubmit}
						validations={validations}
					>
						{({ defaultValues, values }) => (
							<>
								<FormRows>
									<PremiumFeatureSituation
										featureName={GraphQL.AccountFeature.Rendering}
										hideIfUnattainable={true}
										style={PremiumFeatureSituationStyle.Ribbon}
									>
										{({ isFeatureEnabled, premiumAnnotation }) => (
											<DisabledContent
												disabledContent={!isFeatureEnabled}
												disabledOverlay={!isFeatureEnabled && premiumAnnotation}
											>
												<FormRow
													description={(
														<Copy
															{...messages.javascriptRenderingDescription}
															values={{
																trackDom: values.isDomTracked ? 'yes' : 'no',
															}}
														/>
													)}
													htmlFor="isDomTracked"
													label={(
														<FormattedMessage {...messages.javascriptRendering} />
													)}
												>
													<FieldStatus
														name="isDomTracked"
														showIcon={false}
													>
														<SwitchField
															activeStateLabel={(
																<FormattedMessage {...messages.enabled} />
															)}
															inactiveStateLabel={(
																<FormattedMessage {...messages.disabled} />
															)}
															isDisabled={needsVerification}
															name="isDomTracked"
														/>
													</FieldStatus>
												</FormRow>
											</DisabledContent>
										)}
									</PremiumFeatureSituation>

									<PremiumFeatureSituation
										featureName={GraphQL.AccountFeature.LighthouseMonitoring}
										hideIfUnattainable={true}
										style={PremiumFeatureSituationStyle.Ribbon}
									>
										{({ isFeatureEnabled, premiumAnnotation }) => (
											<DisabledContent
												disabledContent={!isFeatureEnabled}
												disabledOverlay={!isFeatureEnabled && premiumAnnotation}
											>
												<FormRow
													htmlFor="isLighthouseMonitored"
													label={(
														<FormattedMessage {...messages.isLighthouseMonitored} />
													)}
												>
													<FieldStatus
														name="isLighthouseMonitored"
														showIcon={false}
													>
														<SwitchField
															activeStateLabel={(
																<FormattedMessage {...messages.enabled} />
															)}
															inactiveStateLabel={(
																<FormattedMessage {...messages.disabled} />
															)}
															isDisabled={needsVerification}
															name="isLighthouseMonitored"
														/>
													</FieldStatus>
												</FormRow>
											</DisabledContent>
										)}
									</PremiumFeatureSituation>

									{values.isLighthouseMonitored && (
										<FormRow
											label={(
												<AttachedElement
													element={(
														<HelpHint
															message={(
																<FormattedMessage {...messages.lighthouseThresholdsHint} />
															)}
														/>
													)}
												>
													<Copy {...messages.lighthouseThresholdsLabel} />
												</AttachedElement>
											)}
										>
											<StaticText>
												{lighthouseThresholds ? (
													<FormattedMessage
														{...messages.lighthouseThresholdsEdit}
														values={{
															linkModal: (chunks) => (
																<InternalLink onClickCallback={openLighthouseThresholdsModal}>
																	{chunks}
																</InternalLink>
															),
															usesDefaultThresholds: usesDefaultLighthouseThresholds ? 'yes' : 'no',
														}}
													/>
												) : (
													<SquareSkeleton
														maxWidth={80}
													/>
												)}
											</StaticText>
										</FormRow>
									)}

									{(values.isDomTracked || values.isLighthouseMonitored) && (
										<FormRow
											label={(
												<AttachedElement
													element={(
														<HelpHint
															message={(
																<FormattedMessage {...messages.onPageRequestBlockingHint} />
															)}
														/>
													)}
												>
													<FormattedMessage {...messages.onPageRequestBlockingLabel} />
												</AttachedElement>
											)}
										>
											<StaticText>
												{onPageRequestBlocking ? (
													<FormattedMessage
														{...messages.onPageRequestBlockingEdit}
														values={{
															count__rules: onPageRequestBlocking.rules.length,
															mode: onPageRequestBlocking.mode,
															link_modal: (chunks) => (
																<InternalLink onClickCallback={openOnPageRequestBlockingModal}>
																	{chunks}
																</InternalLink>
															),
														}}
													/>
												) : (
													<SquareSkeleton
														maxWidth={80}
													/>
												)}
											</StaticText>
										</FormRow>
									)}

									{!defaultValues.isDomTracked && !defaultValues.isLighthouseMonitored
										&& (values.isDomTracked || values.isLighthouseMonitored)
										&& (
											<CalloutMessage
												borders={true}
												message={(
													<FormattedMessage {...messages.onPageRequestBlockingWarningTitle} />
												)}
												size={CalloutMessageSize.Small}
												status={CalloutMessageStatus.Warning}
											>
												<Copy
													{...messages.onPageRequestBlockingWarningDescription}
													values={{
														link_article: (chunks) => (
															<ExternalLink href="https://www.contentkingapp.com/support/javascript-rendering/#effect-on-web-analytics-data">
																{chunks}
															</ExternalLink>
														),
													}}
												/>
											</CalloutMessage>
										)}

									{needsVerification && (
										<CalloutMessage
											borders={true}
											message="Verification needed"
											status={CalloutMessageStatus.Normal}
										>
											<FormattedMessage
												{...messages.needsVerification}
												values={{
													link_verify: (chunks) => (
														<VerifyWebsiteLink>{chunks}</VerifyWebsiteLink>
													),
												}}
											/>
										</CalloutMessage>
									)}
								</FormRows>

								<ButtonsLayout>
									<CancelButton />

									<SaveSubmitButton />
								</ButtonsLayout>
							</>
						)}
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default JavascriptRenderingAndLighthouseForm;
