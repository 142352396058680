import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import DotInterfaceHint from '~/components/logic/DotInterfaceHint';

import {
	UiInteraction,
} from '~/hooks/useUiInteractions';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.lastCheckedAt',
	},
});



type Props = {
	children: React.ReactNode,
};

const AutoprioritizationHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<DotInterfaceHint
			conditionsAlready={[
				({ uiInteractions }) => uiInteractions[UiInteraction.PageDetailOpen] >= 3,
			]}
			content={(
				<Copy {...messages.message} />
			)}
			iconPosition={{
				left: 42,
				top: 9,
			}}
			name={GraphQL.InterfaceHint.LastCrawlTooltip}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(AutoprioritizationHint);
