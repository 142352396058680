import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const RobotDirectivesIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth={(60 / size) * 2}
			>
				<path d="M19.41 15.63H40.5v14.5H19.41zm3.24 18.47h14.6a3.24 3.24 0 0 1 3.24 3.24v2.56A10.55 10.55 0 0 1 30 50.45h0A10.55 10.55 0 0 1 19.41 39.9v-2.56a3.24 3.24 0 0 1 3.24-3.24z" />
				<circle
					cx="25.84"
					cy="22.59"
					r="2.07"
				/>
				<circle
					cx="29.95"
					cy="8.94"
					r="1.6"
				/>
				<circle
					cx="34.07"
					cy="22.59"
					r="2.07"
				/>
				<g strokeLinecap="round">
					<path d="M29.95 10.53v5.1M19.41 36.41h-3.65a2.71 2.71 0 0 0-2.71 2.72v5.49" />
					<path d="M40.5 36.41h3.74A2.71 2.71 0 0 1 47 39.13v5.49m-36.15 3.31v-3.31h4.39v3.31m29.52 0v-3.31h4.39v3.31" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default RobotDirectivesIconBuilder;

