import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum WordPressIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type WordPressIconBuilderProps = {
	variant?: WordPressIconVariants,
};

const WordPressIconBuilder: IconBuilder<WordPressIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = WordPressIconVariants.Active,
		} = props;

		if (variant === WordPressIconVariants.Inactive) {
			return (
				<g
					clipRule="evenodd"
					fillRule="evenodd"
				>
					<path
						d="M24 24.9348H0V0.934814H24V24.9348Z"
						fill="#A5A5A5"
						fillOpacity="0.01"
					/>
					<path
						d="M18.6276 9.31231C19.2156 10.3875 19.5516 11.6223 19.5516 12.9348C19.5516 15.7205 18.0417 18.1533 15.7968 19.4627L18.1036 12.7931C18.5341 11.7158 18.678 10.8537 18.678 10.0883C18.678 9.80476 18.6591 9.54226 18.6276 9.31126V9.31231ZM13.0416 9.38476C13.4952 9.36376 13.9047 9.31126 13.9047 9.31126C14.3121 9.25876 14.2638 8.66026 13.8574 8.68126C13.8574 8.68126 12.6289 8.77576 11.8414 8.77576C11.0959 8.77576 9.84644 8.67076 9.84644 8.67076C9.43694 8.64976 9.38444 9.26926 9.79394 9.29026C9.79394 9.29026 10.1719 9.33226 10.5814 9.35326L11.7574 12.5768L10.0984 17.5328L7.34849 9.36481C7.80209 9.34381 8.21159 9.29446 8.21159 9.29446C8.62109 9.24196 8.57279 8.64346 8.16539 8.66761C8.16539 8.66761 6.94319 8.76421 6.15359 8.76421C6.01394 8.76421 5.84699 8.75896 5.67059 8.75371C7.03769 6.73981 9.36449 5.38531 12 5.38531C13.9656 5.38531 15.7558 6.13606 17.0998 7.36771C17.0683 7.36561 17.0368 7.36141 17.0022 7.36141C16.2598 7.36141 15.7338 8.00716 15.7338 8.70121C15.7338 9.32386 16.0929 9.85096 16.4751 10.4726C16.7628 10.9766 17.0988 11.6223 17.0988 12.5558C17.0988 13.1963 16.8499 13.9523 16.5234 14.9918L15.7705 17.5013L13.0405 9.37426L13.0416 9.38476ZM12 20.4843C11.2587 20.4843 10.5436 20.3762 9.86639 20.1777L12.1323 13.5942L14.4528 19.9541C14.4696 19.9908 14.4874 20.0255 14.5074 20.0591C13.723 20.3342 12.8799 20.4864 12 20.4864V20.4843ZM4.44736 12.9348C4.44736 11.8397 4.68256 10.8002 5.10151 9.86146L8.70301 19.7315C6.18616 18.5072 4.44841 15.9242 4.44841 12.9348H4.44736ZM12 4.53481C7.36948 4.53481 3.59998 8.30431 3.59998 12.9348C3.59998 17.5653 7.36948 21.3348 12 21.3348C16.6305 21.3348 20.4 17.5653 20.4 12.9348C20.4 8.30431 16.6305 4.53481 12 4.53481V4.53481Z"
						fill="#8595A6"
						opacity="0.6"
					/>
				</g>
			);
		}

		return (
			<g>
				<path
					clipRule="evenodd"
					d="M24 24H0V0H24V24Z"
					fill="#A5A5A5"
					fillOpacity="0.01"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M18.6276 8.37747C19.2156 9.45267 19.5516 10.6875 19.5516 12C19.5516 14.7856 18.0417 17.2185 15.7968 18.5278L18.1036 11.8582C18.5341 10.7809 18.678 9.91888 18.678 9.15342C18.678 8.86993 18.6591 8.60742 18.6276 8.37642V8.37747ZM13.0416 8.44993C13.4952 8.42893 13.9047 8.37643 13.9047 8.37643C14.3121 8.32393 14.2638 7.72543 13.8574 7.74643C13.8574 7.74643 12.6289 7.84093 11.8414 7.84093C11.0959 7.84093 9.84643 7.73593 9.84643 7.73593C9.43692 7.71493 9.38442 8.33443 9.79392 8.35543C9.79392 8.35543 10.1719 8.39743 10.5814 8.41843L11.7574 11.6419L10.0984 16.5979L7.34847 8.42998C7.80207 8.40898 8.21158 8.35963 8.21158 8.35963C8.62108 8.30713 8.57277 7.70863 8.16537 7.73278C8.16537 7.73278 6.94318 7.82938 6.15358 7.82938C6.01393 7.82938 5.84698 7.82413 5.67058 7.81888C7.03768 5.80498 9.36447 4.45048 12 4.45048C13.9656 4.45048 15.7558 5.20123 17.0998 6.43288C17.0683 6.43078 17.0368 6.42658 17.0022 6.42658C16.2598 6.42658 15.7338 7.07233 15.7338 7.76638C15.7338 8.38903 16.0929 8.91613 16.4751 9.53773C16.7628 10.0417 17.0988 10.6875 17.0988 11.6209C17.0988 12.2614 16.8499 13.0174 16.5234 14.0569L15.7705 16.5664L13.0405 8.43943L13.0416 8.44993ZM12 19.5495C11.2587 19.5495 10.5436 19.4413 9.86637 19.2429L12.1323 12.6594L14.4528 19.0192C14.4696 19.056 14.4874 19.0906 14.5074 19.1242C13.723 19.3993 12.8799 19.5516 12 19.5516V19.5495ZM4.44733 12C4.44733 10.9048 4.68253 9.86533 5.10148 8.92663L8.70298 18.7966C6.18613 17.5723 4.44838 14.9893 4.44838 12H4.44733ZM12 3.59998C7.36948 3.59998 3.59998 7.36948 3.59998 12C3.59998 16.6305 7.36948 20.4 12 20.4C16.6305 20.4 20.4 16.6305 20.4 12C20.4 7.36948 16.6305 3.59998 12 3.59998Z"
					fill="#2E93F0"
					fillRule="evenodd"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default WordPressIconBuilder;

