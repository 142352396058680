import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum AkamaiIconVariants {
	Default = 'default',
	NotAvailable = 'not-available',
	NotUsed = 'not-used',
}



type AkamaiIconBuilderProps = {
	variant?: AkamaiIconVariants,
};

const AkamaiIconBuilder: IconBuilder<AkamaiIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = AkamaiIconVariants.Default,
		} = props;

		if (variant === AkamaiIconVariants.NotAvailable) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					stroke="none"
					strokeWidth="1"
				>
					<path
						clipRule="evenodd"
						d="M20.04 2.4C20.272 2.58739 20.3082 2.92738 20.1208 3.15939L5.40011 21.1986C5.21272 21.4306 4.87273 21.4667 4.64072 21.2793C4.40871 21.0919 4.37254 20.752 4.55993 20.5199L19.2806 2.48079C19.468 2.24878 19.808 2.21261 20.04 2.4Z"
						fill="#BCC5CF"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M8.15249 14.2394C8.15249 13.9941 8.15249 13.7274 8.15249 13.4607C8.1583 12.4612 8.35753 11.4723 8.7392 10.5485C9.10685 9.62752 9.65033 8.78693 10.3393 8.07369C11.0496 7.36771 11.89 6.8062 12.8141 6.42024C13.5772 6.11295 14.3832 5.92583 15.2036 5.86554L16.6651 4.05209C16.098 3.92377 15.5183 3.85936 14.9369 3.86007C13.9377 3.85838 12.9481 4.05414 12.0247 4.43611C11.0972 4.8156 10.2555 5.37792 9.54992 6.08955C8.84181 6.79809 8.28 7.639 7.89647 8.56439C7.15095 10.3747 7.12804 12.402 7.83247 14.2288C7.97114 14.6021 8.19516 14.6021 8.15249 14.2394Z"
						fill="#C3C9CF"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M19.5772 5.44955L18.8732 6.31361C19.5732 6.55575 20.2264 6.9166 20.804 7.38035C20.9427 7.48702 21.028 7.55103 21.06 7.52969C20.8093 6.69148 20.2878 5.95997 19.5772 5.44955V5.44955Z"
						fill="#C3C9CF"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M7.13909 15.8395C6.59729 14.6318 6.31736 13.323 6.3177 11.9993C6.32037 9.91651 7.00029 7.89109 8.25488 6.22857C9.50947 4.56604 11.2707 3.3566 13.2728 2.78268C13.6889 2.66534 13.5715 2.38799 13.0808 2.38799C11.8129 2.37812 10.5555 2.61952 9.3814 3.09825C8.20727 3.57698 7.13959 4.28355 6.24003 5.17717C5.34046 6.07078 4.62682 7.13374 4.14031 8.30467C3.6538 9.47561 3.40406 10.7313 3.4055 11.9993C3.40148 14.1425 4.12763 16.2231 5.46431 17.8983L7.13909 15.8395Z"
						fill="#C3C9CF"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M18.0198 7.56168H17.9025L17.1771 8.45774C17.538 8.54144 17.8941 8.64471 18.2438 8.76709C19.0802 9.0396 19.882 9.40831 20.6333 9.86583C20.804 9.98317 20.9107 10.0472 20.932 10.0365C20.3206 8.86379 19.2758 7.97589 18.0198 7.56168V7.56168Z"
						fill="#C3C9CF"
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M13.1448 21.1732C11.5506 20.6791 10.1104 19.7827 8.96321 18.5704L7.78979 20.0212C9.37483 21.0582 11.2294 21.6071 13.1235 21.5999C13.5715 21.5999 13.5928 21.3119 13.1448 21.1732Z"
						fill="#C3C9CF"
						fillRule="evenodd"
					/>
				</g>
			);
		}

		if (variant === AkamaiIconVariants.NotUsed) {
			return (
				<g>
					<path
						d="M13.3435 21.1754C9.39558 19.9704 6.51501 16.3209 6.51501 12.0057C6.51854 9.92336 7.19874 7.89854 8.45313 6.23636C9.70752 4.57419 11.4681 3.36472 13.4697 2.7902C13.8829 2.67543 13.7681 2.4 13.2746 2.4C7.93808 2.4 3.60001 6.70365 3.60001 12.0057C3.60001 17.3078 7.92661 21.6 13.2746 21.6C13.7681 21.6 13.791 21.3131 13.3435 21.1754Z"
						fill="#96A0AB"
					/>
					<path
						d="M8.35122 14.2437C8.32826 13.9912 8.30531 13.7272 8.30531 13.4633C8.30471 12.4624 8.5014 11.4712 8.88414 10.5464C9.26689 9.62159 9.82817 8.7813 10.5359 8.07357C11.2436 7.36583 12.0839 6.80455 13.0087 6.4218C13.9336 6.03906 14.9247 5.84237 15.9256 5.84297C19.9079 5.84297 21.1015 7.62181 21.2507 7.50705C21.3999 7.39228 19.8047 3.85755 15.1338 3.85755C14.1329 3.85695 13.1417 4.05364 12.2169 4.43639C11.2921 4.81913 10.4518 5.38042 9.74403 6.08815C9.0363 6.79588 8.47502 7.63618 8.09227 8.56099C7.70953 9.4858 7.51284 10.477 7.51344 11.4779C7.51182 12.4202 7.687 13.3545 8.02988 14.2322C8.16759 14.5995 8.38564 14.5995 8.35122 14.2437Z"
						fill="#96A0AB"
					/>
					<path
						d="M11.5417 8.75793C13.5156 7.89721 16.006 7.87425 18.4505 8.72351C20.0916 9.29733 21.0441 10.1121 21.1245 10.0777C21.2507 10.0203 20.1719 8.29888 18.2094 7.56439C17.0857 7.13766 15.8708 7.00851 14.6824 7.18948C13.4941 7.37044 12.3727 7.85538 11.4269 8.59727C11.2203 8.75793 11.3007 8.86122 11.5417 8.75793Z"
						fill="#96A0AB"
					/>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M13.3435 21.1754C9.39558 19.9704 6.51501 16.3209 6.51501 12.0057C6.51854 9.92336 7.19874 7.89854 8.45313 6.23636C9.70752 4.57419 11.4681 3.36472 13.4697 2.7902C13.8829 2.67543 13.7681 2.4 13.2746 2.4C7.93808 2.4 3.60001 6.70365 3.60001 12.0057C3.60001 17.3078 7.92661 21.6 13.2746 21.6C13.7681 21.6 13.791 21.3131 13.3435 21.1754Z"
					fill="#009BDE"
				/>
				<path
					d="M8.35122 14.2437C8.32826 13.9912 8.30531 13.7272 8.30531 13.4633C8.30471 12.4624 8.5014 11.4712 8.88414 10.5464C9.26689 9.62159 9.82817 8.7813 10.5359 8.07356C11.2436 7.36583 12.0839 6.80454 13.0087 6.4218C13.9336 6.03906 14.9247 5.84236 15.9256 5.84297C19.9079 5.84297 21.1015 7.62181 21.2507 7.50704C21.3999 7.39228 19.8047 3.85755 15.1338 3.85755C14.1329 3.85695 13.1417 4.05364 12.2169 4.43639C11.2921 4.81913 10.4518 5.38041 9.74403 6.08815C9.0363 6.79588 8.47502 7.63618 8.09227 8.56099C7.70953 9.4858 7.51284 10.477 7.51344 11.4779C7.51182 12.4202 7.687 13.3545 8.02988 14.2322C8.16759 14.5995 8.38564 14.5995 8.35122 14.2437Z"
					fill="#009BDE"
				/>
				<path
					d="M11.5417 8.75793C13.5156 7.89721 16.006 7.87425 18.4505 8.72351C20.0916 9.29733 21.0441 10.1121 21.1245 10.0777C21.2507 10.0203 20.1719 8.29888 18.2094 7.56439C17.0857 7.13766 15.8708 7.00851 14.6824 7.18948C13.4941 7.37044 12.3727 7.85538 11.4269 8.59727C11.2203 8.75793 11.3007 8.86122 11.5417 8.75793Z"
					fill="#009BDE"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default AkamaiIconBuilder;

