import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const HreflangIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth={(60 / size) * 1.85}
			>
				<path
					d="M25.46 30.25h-2.75l-6.7 7.4v-7.4h-4.86V11.29h25.06v15.26"
					strokeLinecap="round"
				/>
				<path d="M25.46 43.8h12.67l6.25 6.91V43.8h4.47V26.55H25.46V43.8z" />
				<g strokeLinecap="round">
					<path d="M19.65 23.82l4.03-8.06 4.03 8.06M26.4 21.6h-5.45m13.13 11.94l6.42 6.42" />
					<path d="M39.29 33.06c.68 2.51-2.24 6.7-6.79 6.67m.98-6.11l6.84-.86m-4.03-3.2a1.8 1.8 0 0 1 1.11.89" />
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default HreflangIconBuilder;

