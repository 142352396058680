import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import AttachedEnterpriseRibbon from '~/components/patterns/pricingPlans/AttachedEnterpriseRibbon';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import PlanCard from '~/components/patterns/pricingPlans/PlanCard';
import PlanName from '~/components/names/PlanName';
import PlanPriceSummary, {
	PlanPriceSummaryView,
} from './PlanPriceSummary';

import {
	isEnterprisePlan,
} from '~/model/plans';



type Props = {
	accountId: CK.AccountId,
	disabledPlans: Partial<Record<GraphQL.AccountPlan, 'accountTooLarge' | 'billingCycleUnavailable' | 'disallowed' | undefined>>,
	onPlanClick: (plan: GraphQL.AccountPlan) => void,
	plans: ReadonlyArray<GraphQL.AccountPlan>,
};

const PlansOverviewSection: React.FC<Props> = (props) => {
	const {
		accountId,
		disabledPlans,
		onPlanClick,
		plans,
	} = props;

	return (
		<List size={ListSize.Small}>
			{plans.map((plan, index) => {
				const isDisabled = disabledPlans[plan] !== undefined;

				return (
					<PlanCard
						isDisabled={isDisabled}
						isEnterprise={isEnterprisePlan(plan)}
						key={'plan-card-' + index}
						name={(
							isEnterprisePlan(plan) ? (
								<AttachedEnterpriseRibbon>
									<PlanName plan={plan} />
								</AttachedEnterpriseRibbon>
							) : (
								<PlanName plan={plan} />
							)
						)}
						onClick={() => onPlanClick(plan)}
						priceOverview={(
							<PlanPriceSummary
								accountId={accountId}
								disabledReason={disabledPlans[plan]}
								plan={plan}
								view={PlanPriceSummaryView.Overview}
							/>
						)}
					/>
				);
			})}
		</List>
	);
};



export default PlansOverviewSection;
