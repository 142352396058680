import React from 'react';
import times from 'lodash/times';

import ArrayGroupFieldTable, {
	type Field as TableField,
	type FieldRendererProps as TableFieldRendererProps,
} from './ArrayGroupFieldTable.part';
import {
	type FormOnChangeHandlerOptions,
} from '~/components/atoms/forms/basis/Form';
import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';

import useFormContext from '~/hooks/useFormContext';
import useFormFieldsContext from '~/hooks/useFormFieldsContext';
import useFormValidations from '~/hooks/useFormValidations';

import arrayMove from '~/utilities/arrayMove';
import {
	assertString,
} from '~/utilities/typeCheck';
import {
	type Rule,
	type Values,
} from '~/utilities/validations';

import {
	validateField,
	// eslint-disable-next-line import-x/no-restricted-paths
} from '~/components/app/validations';



const FallbackDefaultValue = [];
const FallbackValidation = () => [];



function createInternalFormFieldName(name: string, row: Row, field: Field) {
	return `${name}/${row.id}/${field.name}`;
}

function createInternalFormFields(
	name: string,
	fields: Array<Field>,
	rows: Array<Row>,
	values: Values = [],
) {
	return fields.flatMap((field) => {
		return rows.map((row, index) => {
			const defaultValue = (
				values[index]?.[field.name]
				?? field.defaultValue
			);

			return {
				_originalName: field.name,
				defaultValue,
				id: row.id,
				name: createInternalFormFieldName(name, row, field),
				validation: field.validation ?? FallbackValidation,
			};
		});
	});
}



export type ArrayGroupFieldRendererProps = TableFieldRendererProps;

export type ArrayGroupFieldValidationInput = {
	f: Parameters<Parameters<typeof validateField>[1]>[0],
	getDefaultValueInRow: (fieldName: string) => any,
	getValueInRow: (values: Values, fieldName: string) => any,
	listRows: (values: Values) => Array<Record<string, any>>,
	// Represent the identity of a row. This value _does not_ change when sorting rows.
	rowId: number,
	// Represent the position of a row. This value _does_ change when sorting rrows.
	rowIndex: number,
};

export type ArrayGroupFieldRowDisabledInput = {
	// Represent the identity of a row. This value _does not_ change when sorting rows.
	rowId: number,
	// Represent the position of a row. This value _does_ change when sorting rrows.
	rowIndex: number,
	values: Record<string, any>,
};

type Field = TableField & {
	defaultValue?: any,
	name: string,
	validation?: (input: ArrayGroupFieldValidationInput) => Array<Rule>,
};

type Row = {
	id: number,
};

type Props = {
	addButtonLabel?: React.ReactNode,
	fields: Array<Field>,
	isRowDisabled?: (input: ArrayGroupFieldRowDisabledInput) => boolean,
	isSortable?: boolean,
	maximumRows?: number,
	minimumRows?: number,
	name: string,
	rowHeight?: number,
	showAddButton?: boolean,
	showHeaderWhenEmpty?: boolean,
	showRowNumbers?: boolean,
};

const ArrayGroupField: React.FC<Props> = (props) => {
	const {
		addButtonLabel,
		fields,
		isSortable,
		isRowDisabled,
		name,
		minimumRows = 0,
		maximumRows = Number.MAX_SAFE_INTEGER,
		rowHeight,
		showAddButton = true,
		showHeaderWhenEmpty = true,
		showRowNumbers = false,
	} = props;

	const externalFormContext = useFormContext();
	const externalFormContextOnChangeHandler = externalFormContext.onChangeHandler;
	const externalFormDefaultValue = externalFormContext.defaultValues[name] ?? FallbackDefaultValue;

	const highestRowId = React.useRef(
		Math.max(
			externalFormDefaultValue.length - 1,
			minimumRows - 1,
		),
	);

	const [rows, setRows] = React.useState<Array<Row>>(
		times(Math.max(externalFormDefaultValue.length, minimumRows), (index) => ({ id: index })),
	);

	const [internalFormFields, setInternalFormFields] = React.useState(
		createInternalFormFields(name, fields, rows, externalFormDefaultValue),
	);
	const internalFormFieldsContext = useFormFieldsContext(internalFormFields);

	const internalFormContext = React.useMemo(
		() => {
			const valuesSelector = internalFormFieldsContext.contextForProvider.valuesSelector;
			const onChangeHandler = internalFormFieldsContext.contextForProvider.onChangeHandler;

			// Remap the values from the parent context to the repeated rows.
			// The parent context values are expected to be in the format:
			//
			// {
			// 	users: [
			//		{ firstname: "Andre", lastname: "Nanninga" },
			//		{ firstname: "John", lastname: "Smith" },
			// 	],
			// }
			//
			// The repeated rows values are expected to be in the format:
			//
			// {
			// 	"users.0.firstname": "Andre",
			// 	"users.0.lastname": "Nanninga",
			// 	"users.1.firstname": "John",
			// 	"users.1.lastname": "Smith",
			// }
			function arrayGroupValuesSelector(values: Values) {
				// Run the external context values selector first to ensure that the external context values
				// are as expected.
				const externalValues = valuesSelector(values);

				const rowsValues = {};
				rows.forEach((row, index) => {
					fields.forEach((field) => {
						const fieldName = createInternalFormFieldName(name, row, field);
						const fieldValue = externalValues[name]?.[index]?.[field.name];

						rowsValues[fieldName] = fieldValue;
					});
				});

				return rowsValues;
			}

			// Remap the onChangeHandler to update the parent context values.
			// Reverse the process of the arrayGroupValuesSelector.
			async function arrayGroupChangeHandler(
				fieldName: string,
				fieldValue: any,
				options?: FormOnChangeHandlerOptions,
			) {
				await onChangeHandler(fieldName, fieldValue, options);

				const externalFieldValues = externalFormContext.values[name];

				const [rowId, field] = fieldName.split('/').slice(-2);
				assertString(rowId);
				assertString(field);

				const rowIndex = rows.findIndex((row) => row.id === Number(rowId));

				if (externalFieldValues[rowIndex]?.[field] !== fieldValue) {
					const nextExternalFieldValues = structuredClone(externalFieldValues);

					nextExternalFieldValues[rowIndex] = nextExternalFieldValues[rowIndex] ?? {};
					nextExternalFieldValues[rowIndex][field] = fieldValue;

					externalFormContextOnChangeHandler(name, nextExternalFieldValues, options);
				}

				return Promise.resolve();
			}

			return {
				...internalFormFieldsContext.contextForProvider,
				onChangeHandler: arrayGroupChangeHandler,
				valuesSelector: arrayGroupValuesSelector,
			};
		},
		[
			fields,
			externalFormContext.values,
			externalFormContextOnChangeHandler,
			internalFormFieldsContext.contextForProvider,
			name,
			rows,
		],
	);

	useFormValidations(
		name,
		React.useCallback(
			() => {
				const validations: Record<string, Array<Rule>> = {};

				internalFormFields.forEach((field) => {
					const fieldValidation = field.validation;
					const rowIndex = rows.findIndex((row) => row.id === field.id);

					validations[field.name] = validateField(field.name, (f) => {
						function getValueInRow(values: Values, fieldName: string) {
							return values[name]?.[rowIndex]?.[fieldName];
						}

						function getDefaultValueInRow(fieldName: string) {
							return externalFormDefaultValue[field.id]?.[fieldName];
						}

						function listRows(values: Values) {
							return values[name];
						}

						f.setValueSelector(
							(values) => {
								return getValueInRow(values, field._originalName);
							},
						);

						const input: ArrayGroupFieldValidationInput = {
							f,
							getDefaultValueInRow,
							getValueInRow,
							listRows,
							rowId: field.id,
							rowIndex,
						};

						return fieldValidation(input);
					});
				});

				return validations;
			},
			[
				externalFormDefaultValue,
				internalFormFields,
				name,
				rows,
			],
		),
	);

	const getRowValues = React.useCallback(
		(rowIndex: number) => {
			return externalFormContext.values[name]?.[rowIndex] ?? {};
		},
		[
			externalFormContext.values,
			name,
		],
	);

	const addRow = React.useCallback(
		async () => {
			highestRowId.current += 1;

			const nextExternalItem = {};
			fields.forEach((field) => {
				nextExternalItem[field.name] = field.defaultValue;
			});

			const nextExternalValues = structuredClone(externalFormContext.values) as Record<string, any>;

			if (nextExternalValues[name] === undefined) {
				nextExternalValues[name] = [];
			}

			nextExternalValues[name].push(nextExternalItem);
			await externalFormContext.setValues(nextExternalValues);

			setRows((previousRows) => {
				const nextRows = [
					...previousRows,
					{ id: highestRowId.current },
				];

				setInternalFormFields(
					createInternalFormFields(
						name,
						fields,
						nextRows,
						externalFormContext.values[name],
					),
				);

				return nextRows;
			});

			externalFormContext.onChangeHandler(name, nextExternalValues[name]);
		},
		[
			externalFormContext,
			fields,
			name,
		],
	);

	const moveRow = React.useCallback(
		async (sourceIndex: number, destinationIndex: number) => {
			setRows((previousRows) => {
				const nextRows = arrayMove(previousRows, sourceIndex, destinationIndex);

				const nextExternalValues = structuredClone<Record<string, any>>(externalFormContext.values);
				nextExternalValues[name] = arrayMove(nextExternalValues[name], sourceIndex, destinationIndex);

				const nextInternalFormFields = createInternalFormFields(
					name,
					fields,
					nextRows,
					nextExternalValues[name],
				);

				setInternalFormFields(nextInternalFormFields);
				externalFormContext.setValues(nextExternalValues);
				nextInternalFormFields.forEach((field) => {
					externalFormContext.onMountHandler(field.name, { interacted: true });
				});

				externalFormContext.onChangeHandler(name, nextExternalValues[name]);

				return nextRows;
			});
		},
		[externalFormContext, fields, name],
	);

	const removeRow = React.useCallback(
		async (rowId: number) => {
			const rowIndex = rows.findIndex((row) => row.id === rowId);

			const nextExternalValues = structuredClone(externalFormContext.values);
			nextExternalValues[name].splice(rowIndex, 1);

			const nextRows = rows.filter((row) => row.id !== rowId);

			if (nextRows.length < minimumRows) {
				const nextExternalItem = {};
				fields.forEach((field) => {
					nextExternalItem[field.name] = field.defaultValue;
				});
				nextExternalValues[name].push(nextExternalItem);

				highestRowId.current += 1;
				nextRows.push({ id: highestRowId.current });
			}

			setInternalFormFields(
				createInternalFormFields(
					name,
					fields,
					nextRows,
					nextExternalValues[name],
				),
			);

			setRows(nextRows);
			await externalFormContext.setValues(nextExternalValues);

			setTimeout(() => {
				externalFormContext.onChangeHandler(name, nextExternalValues[name]);
			}, 10);
		},
		[
			externalFormContext,
			fields,
			minimumRows,
			name,
			rows,
		],
	);

	const isTableRowDisabled = React.useCallback(
		(rowId: number, rowIndex: number) => {
			if (!isRowDisabled) {
				return false;
			}

			const rowValues = externalFormContext.values[name][rowIndex];

			return isRowDisabled({
				rowId,
				rowIndex,
				values: rowValues,
			});
		},
		[
			externalFormContext.values,
			isRowDisabled,
			name,
		],
	);

	return (
		<FormFieldsContext context={internalFormContext}>
			<ArrayGroupFieldTable
				addButtonLabel={addButtonLabel}
				fields={fields}
				getRowValues={getRowValues}
				isRowDisabled={isTableRowDisabled}
				isSortable={isSortable}
				maximumRows={maximumRows}
				name={name}
				onAddRow={addRow}
				onMoveRow={moveRow}
				onRemoveRow={removeRow}
				rowHeight={rowHeight}
				rows={rows}
				showAddButton={showAddButton}
				showHeaderWhenEmpty={showHeaderWhenEmpty}
				showRowNumbers={showRowNumbers}
			/>
		</FormFieldsContext>
	);
};



export default ArrayGroupField;
