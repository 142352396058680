import Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FilterDefinitionFormatter, {
	FilterDefinitionFormatterStyle,
} from '~/components/logic/filters/FilterDefinitionFormatter';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	usePrioritizeUrlsByFilterMutation,
} from '~/components/app/WebsiteAdminPrioritizeUrlsByFilterModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useCountPages from '~/hooks/useCountPages';

import {
	getFilter,
	removeDefaultFilterValues,
} from '~/model/pages';

import decodeFromBase64 from '~/utilities/decodeFromBase64';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminPrioritizeUrlsByFilterModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const [prioritizeUrlsByFilter] = usePrioritizeUrlsByFilterMutation();

	const [filter, setFilter] = React.useState<any>(null);

	const numberOfPagesToBePrioritized = useCountPages({
		filter: filter ?? [],
		skip: filter === null,
		pollIntervalInMilliseconds: 0,
		websiteId,
	});

	const handleChange = React.useCallback(
		(field, value) => {
			if (field !== 'pagesOverviewUrl') {
				return;
			}

			try {
				const url = new URL(value);
				const filterString = url.searchParams.get('filter') ?? '';

				const filter = removeDefaultFilterValues(
					Immutable.fromJS(JSON.parse(
						decodeFromBase64(filterString),
					).filter),
				).toJS();

				setFilter(filter);
			} catch (error) {
				console.error('Invalid filter URL', error);

				setFilter(null);
				return;
			}
		},
		[],
	);

	const handleSubmit = React.useCallback(
		async () => {
			const {
				criteria,
			} = getFilter(Immutable.fromJS(filter));

			await prioritizeUrlsByFilter({
				variables: {
					filter: criteria,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			filter,
			prioritizeUrlsByFilter,
			websiteId,
		],
	);

	const validations = React.useMemo(
		() => ({
			validatePagesOverviewUrl: validateField(
				'pagesOverviewUrl',
				(f) => [
					f.validateNonEmpty(),
					f.custom({
						rule: ({ value }) => {
							const pattern = /\/websites\/(.*)\/pages/;

							const urlWebsiteId = value.match(pattern)?.[1];

							return urlWebsiteId === websiteId;
						},
						message: 'Url does not match current website',
					}),
					f.custom({
						rule: () => {
							return filter !== null;
						},
						message: 'Invalid filter',
					}),
				],
			),
		}),
		[
			filter,
			websiteId,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			size={SimpleModalSize.XLarge}
			title="Prioritize Urls By Filter"
		>
			<Form
				defaultFocus="pagesOverviewUrl"
				defaultValues={{
					pagesOverviewUrl: '',
				}}
				onChangeCallback={handleChange}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FormRow
						description={(
							<>
								Filter the pages overview to show only the pages which need to be prioritizes and copy/paste the url into this form.
							</>
						)}
						fullwidth={true}
						htmlFor="pagesOverviewUrl"
						label="Pages url"
					>
						<FieldStatus
							name="validatePagesOverviewUrl"
							showIcon={false}
						>
							<TextField
								name="pagesOverviewUrl"
								width="100%"
							/>
						</FieldStatus>
					</FormRow>

					<FormRow label="Number of pages">
						<StaticText>
							{numberOfPagesToBePrioritized.total}
						</StaticText>
					</FormRow>

					<FormRow
						fullwidth={true}
						label="Filter"
					>
						<StaticText width="100%">
							{filter !== null && (
								<FilterDefinitionFormatter
									filterDefinition={filter}
									style={FilterDefinitionFormatterStyle.CompactList}
								/>
							)}
						</StaticText>
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />

					<SubmitButton>
						Prioritize urls
					</SubmitButton>
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default WebsiteAdminPrioritizeUrlsByFilterModal;
