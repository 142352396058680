import React from 'react';
import {
	type Store,
} from 'redux';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

import type CK from '~/types/contentking';

import useAccountId from '~/hooks/useAccountId';
import useAccountWebsiteCount from '~/hooks/useAccountWebsiteCount';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useUiInteractions from './useUiInteractions';

import {
	changeUrlState,
	initialRouterState,
} from '~/actions';

import {
	configureRouter,
} from '~/routing/configuration';

import {
	getRouter,
	recreateRouter,
} from '~/routing/router';



function useSetupRouter(store: Store) {
	const router = getRouter();

	const accountId = useAccountId();
	const homeAccountIds = useHomeAccountIds();
	const setupUiInteractionsListener = useUiInteractions((state) => state.setupRouterListener);

	const accountNumberOfWebsites = useAccountWebsiteCount(accountId);

	const context = React.useRef<{
		accountNumberOfWebsites: number | null,
		homeAccountIds: ReadonlyArray<CK.AccountId> | null,
	}>({
		accountNumberOfWebsites: null,
		homeAccountIds: null,
	});

	context.current.accountNumberOfWebsites = accountNumberOfWebsites;
	context.current.homeAccountIds = homeAccountIds;

	React.useEffect(
		() => {
			let router = getRouter();
			if (router.isStarted()) {
				router = recreateRouter();
			}

			router.usePlugin(browserPlugin());
			router.usePlugin(listenersPlugin());

			configureRouter(router);

			router.start();

			router.setDependencies({ store });

			router.canActivate('account.websites', () => (toState, fromState, done) => {
				if (
					fromState.params.websiteId
					&& toState.params.websiteId
					&& ((fromState.params.accountId + '') !== (toState.params.accountId + ''))
				) {
					return Promise.reject({
						redirect: {
							name: 'account.websites',
							params: {
								accountId: toState.params.accountId ? toState.params.accountId + '' : null,
							},
						},
					});
				}

				if (!toState.params.view) {
					if (context.current.accountNumberOfWebsites !== null) {
						return Promise.reject({
							redirect: {
								name: toState.name,
								params: Object.assign({}, toState.params, {
									view: (
										fromState.name.indexOf('account.websites') === 0 && fromState.params.view
											? fromState.params.view
											: (context.current.accountNumberOfWebsites < 7 ? 'grid' : 'list')
									),
								}),
							},
						});
					}
				}

				done();
			});

			router.canActivate('account.members', () => (toState, fromState, done) => {
				if (
					fromState.params.memberDetailId
					&& toState.params.memberDetailId
					&& ((fromState.params.accountId + '') !== (toState.params.accountId + ''))
				) {
					return Promise.reject({
						redirect: {
							name: 'account.members',
							params: {
								accountId: toState.params.accountId ? toState.params.accountId + '' : null,
							},
						},
					});
				}

				done();
			});

			router.canActivate('userProfile', () => (toState, fromState, done) => {
				if (
					context.current.homeAccountIds !== null
					&& toState.params.accountId
					&& context.current.homeAccountIds.includes(parseInt(toState.params.accountId)) === false
				) {
					return Promise.reject({
						redirect: {
							name: 'account.members',
							params: {
								accountId: toState.params.accountId,
							},
						},
					});
				}

				done();
			});

			router.addListener((toState, fromState) => {
				store.dispatch(
					changeUrlState(toState, fromState),
				);
			});

			setupUiInteractionsListener(router);

			store.dispatch(
				initialRouterState(
					router.getState(),
				),
			);
		},
		[
			context,
			router,
			setupUiInteractionsListener,
			store,
		],
	);

	return router;
}



export default useSetupRouter;
