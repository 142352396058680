import React from 'react';

import type GraphQL from '~/types/graphql';

import InterfaceHint from '~/components/patterns/hints/InterfaceHint';

import useInterfaceHint, {
	type Conditional,
} from '~/hooks/useInterfaceHint';



type Props = {
	children?: React.ReactNode,
	conditionsAlready?: Array<Conditional>,
	conditionsNotAnymore?: Array<Conditional>,
	content?: React.ReactNode,
	iconPosition?: {
		bottom?: number | string,
		left?: number | string,
		right?: number | string,
		top?: number | string,
	},
	inline?: boolean,
	isObsolete?: boolean,
	name: GraphQL.InterfaceHint,
};

const DotInterfaceHint: React.FC<Props> = (props) => {
	const {
		children,
		conditionsAlready = [],
		conditionsNotAnymore = [],
		content,
		iconPosition,
		inline = true,
		isObsolete = false,
		name,
	} = props;

	const interfaceHint = useInterfaceHint({
		conditionsAlready,
		conditionsNotAnymore,
		isObsolete,
		name,
	});

	return (
		<InterfaceHint
			bubble={content}
			disabled={!interfaceHint.shouldBeDisplayed}
			inline={inline}
			onCloseCallback={interfaceHint.markClosed}
			onOpenCallback={interfaceHint.markOpened}
			toggleIconPosition={iconPosition}
		>
			{children}
		</InterfaceHint>
	);
};



export default DotInterfaceHint;
