import React from 'react';

import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import useCurrentUserId from './useCurrentUserId';
import useRoyalMode from './useRoyalMode';
import useUiInteractions, {
	type UiInteractions,
} from './useUiInteractions';
import useUserEmail from './useUserEmail';
import useUserUniqueId from './useUserUniqueId';

import {
	useInterfaceHintsQuery,
	useRememberDisplayedInterfaceHintsMutation,
} from './useInterfaceHint.gql';



export type Conditional = (context: Context) => boolean;

export type Context = {
	uiInteractions: UiInteractions,
};



function shouldHintBeDisplayedAlready(
	hintName: GraphQL.InterfaceHint,
	conditionsAlready: Array<Conditional>,
	conditionsNotAnymore: Array<Conditional>,
	context: Context,
	interfaceHints: ReadonlyArray<GraphQL.InterfaceHintStatus>,
	openedHint: GraphQL.InterfaceHint | null,
) {
	const hintState = interfaceHints.find(
		(hint) => hint.name === hintName,
	);

	if (!hintState) {
		return false;
	}

	if (hintState.displayed && openedHint !== hintName) {
		return false;
	}

	if (conditionsAlready.length === 0 && conditionsNotAnymore.length === 0) {
		return true;
	}

	return (
		!conditionsAlready.some((condition) => !condition(context))
		&& !conditionsNotAnymore.some((condition) => !condition(context))
	);
}

function shouldHintNotBeDisplayedAnymore(
	hintName: GraphQL.InterfaceHint,
	conditionsNotAnymore: Array<Conditional>,
	context: Context,
	interfaceHints: ReadonlyArray<GraphQL.InterfaceHintStatus>,
) {
	const hintState = interfaceHints.find(
		(hint) => hint.name === hintName,
	);

	if (!hintState) {
		return false;
	}

	if (hintState.displayed) {
		return false;
	}

	if (conditionsNotAnymore.length === 0) {
		return false;
	}

	return conditionsNotAnymore.every((condition) => !condition(context));
}



const defaultInterfaceHints: ReadonlyArray<GraphQL.InterfaceHintStatus> = [];
let openedHint: GraphQL.InterfaceHint | null = null;

type Options = {
	name: GraphQL.InterfaceHint,
	conditionsAlready: Array<Conditional>,
	conditionsNotAnymore: Array<Conditional>,
	isObsolete?: boolean,
};

function useInterfaceHint(options: Options) {
	const {
		name,
		conditionsAlready,
		conditionsNotAnymore,
		isObsolete = false,
	} = options;

	const legacyUserId = useCurrentUserId();
	const userEmail = useUserEmail(legacyUserId);
	const userId = useUserUniqueId(legacyUserId);

	const isImpersonated = useRoyalMode().isImpersonated;

	const { data, loading } = useInterfaceHintsQuery({
		variables: {
			userId: userId ?? ('' as CK.UserId),
		},
		skip: !userId,
		onCompleted: () => {
			pendingRequestsRef.current.forEach((callback) => {
				if (shouldBeDisplayedRef.current) {
					callback();
				}
			});
		},
	});

	const [rememberDisplayedInterfaceHint] = useRememberDisplayedInterfaceHintsMutation();

	const interfaceHints = data?.user?.interfaceHints ?? defaultInterfaceHints;
	const uiInteractions = useUiInteractions((store) => store.interactions);

	const dispatch = useDispatch();

	const rules = React.useRef({
		conditionsAlready,
		conditionsNotAnymore,
	});

	const pendingRequestsRef = React.useRef<Array<() => void>>([]);

	const context = React.useMemo(
		() => ({
			uiInteractions,
		}),
		[
			uiInteractions,
		],
	);

	const shouldBeDisplayed = React.useMemo(
		() => {
			return shouldHintBeDisplayedAlready(
				name,
				rules.current.conditionsAlready,
				isObsolete
					? [() => true]
					: rules.current.conditionsNotAnymore,
				context,
				interfaceHints,
				openedHint,
			);
		},
		[
			context,
			interfaceHints,
			isObsolete,
			name,
		],
	);

	const shouldBeDisplayedRef = React.useRef(shouldBeDisplayed);

	const display = React.useCallback(
		(callback: () => void) => {
			if (loading) {
				pendingRequestsRef.current.push(callback);
			} else if (shouldBeDisplayedRef.current) {
				callback();
			}
		},
		[
			loading,
		],
	);

	const markClosed = React.useCallback(
		() => {
			openedHint = null;
		},
		[],
	);

	const markOpened = React.useCallback(
		() => {
			openedHint = name;

			if (
				!isImpersonated
				&& userEmail !== null
				&& userId !== null
			) {
				rememberDisplayedInterfaceHint({
					variables: {
						email: userEmail,
						interfaceHints: [name],
						userId,
					},
				});
			}
		},
		[
			isImpersonated,
			name,
			rememberDisplayedInterfaceHint,
			userEmail,
			userId,
		],
	);

	React.useEffect(
		() => {
			if (shouldHintNotBeDisplayedAnymore(
				name,
				isObsolete
					? [() => true]
					: rules.current.conditionsNotAnymore,
				context,
				interfaceHints,
			)) {
				markOpened();
				openedHint = null;
			}
		},
		[
			context,
			dispatch,
			interfaceHints,
			name,
			rules,
			isObsolete,
			markOpened,
		],
	);

	return React.useMemo(
		() => ({
			display,
			markClosed,
			markOpened,
			shouldBeDisplayed,
		}),
		[
			display,
			markClosed,
			markOpened,
			shouldBeDisplayed,
		],
	);
}



export default useInterfaceHint;
