import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum MicrosoftTeamsIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type MicrosoftTeamsIconBuilderProps = {
	variant?: MicrosoftTeamsIconVariants,
};

const MicrosoftTeamsIconBuilder: IconBuilder<MicrosoftTeamsIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = MicrosoftTeamsIconVariants.Active,
		} = props;

		if (variant === MicrosoftTeamsIconVariants.Inactive) {
			return (
				<g
					clipRule="evenodd"
					fill="none"
					fillRule="evenodd"
					id="Page-1"
					stroke="none"
					strokeWidth="1"
				>
					<path
						d="M18.7487 11.0779C18.5567 10.9962 18.3829 10.8791 18.2355 10.7317C18.0888 10.5843 17.9709 10.4097 17.8893 10.2184C17.7215 9.81705 17.7215 9.36579 17.8893 8.96518C18.0533 8.57817 18.3617 8.27053 18.7487 8.10575C19.1501 7.93794 19.6013 7.93794 20.0019 8.10575C20.389 8.26977 20.6966 8.57817 20.8614 8.96518C21.0292 9.36655 21.0292 9.81781 20.8614 10.2184C20.6974 10.6054 20.389 10.9131 20.0019 11.0779C19.6006 11.2457 19.1493 11.2457 18.7487 11.0779ZM17.7638 17.0803V12.2268H20.999V15.4075C21.0103 15.694 20.9294 15.9759 20.7684 16.2125C20.615 16.4257 20.4154 16.6026 20.1841 16.728C19.9392 16.8611 19.6747 16.9533 19.4003 17.0017C19.1274 17.0531 18.8507 17.0788 18.5726 17.0803H17.7638ZM15.7683 10.0657C15.5702 10.1504 15.3571 10.1935 15.1424 10.192C14.9277 10.1935 14.7153 10.1512 14.5181 10.0657C14.4466 10.0352 14.3992 10.0003 14.346 9.96112C14.2899 9.91988 14.2274 9.87388 14.1235 9.8231V7.33626C14.2271 7.28582 14.2897 7.23976 14.3459 7.19839C14.3991 7.15917 14.4467 7.12416 14.5181 7.09363C14.7146 7.00897 14.9262 6.96588 15.1401 6.96739C15.3556 6.96588 15.5687 7.00897 15.7668 7.09363C16.1538 7.25765 16.4614 7.56605 16.6262 7.95306C16.7948 8.35443 16.7948 8.80569 16.627 9.2063C16.4629 9.59331 16.1553 9.90095 15.7683 10.0657ZM14.3286 18.4145C14.2736 18.3996 14.2105 18.3826 14.1235 18.3638V11.2154H17.1568V16.8316C17.1674 17.1135 17.0971 17.3932 16.9535 17.6358C16.8235 17.8437 16.6474 18.0191 16.438 18.1476C16.2248 18.2768 15.9905 18.3683 15.7464 18.4174C15.5067 18.4688 15.2618 18.4945 15.0169 18.496C14.8582 18.4953 14.6995 18.4839 14.5422 18.4613C14.4604 18.4501 14.4016 18.4342 14.3286 18.4145ZM13.5165 21.7319L3 19.9118V5.75497L13.5165 3.93481V21.7319ZM5.83139 9.69071L10.6849 9.39517V10.6083L8.86472 10.6892V15.9985L7.65154 15.9275V10.7368L5.83139 10.8102V9.69071Z"
						fill="#BEC5CB"
					/>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-1"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M18.2355 9.79685C18.3829 9.94425 18.5567 10.0614 18.7487 10.143C19.1493 10.3108 19.6006 10.3108 20.0019 10.143C20.389 9.97826 20.6974 9.67062 20.8614 9.28361C21.0292 8.88299 21.0292 8.43174 20.8614 8.03036C20.6966 7.64335 20.389 7.33496 20.0019 7.17093C19.6013 7.00313 19.1501 7.00313 18.7487 7.17093C18.3617 7.33571 18.0533 7.64335 17.8893 8.03036C17.7215 8.43098 17.7215 8.88224 17.8893 9.28361C17.9709 9.47485 18.0888 9.64945 18.2355 9.79685ZM17.7638 11.292V16.1455H18.5726C18.8507 16.144 19.1274 16.1183 19.4003 16.0669C19.6747 16.0185 19.9392 15.9263 20.1841 15.7932C20.4154 15.6677 20.615 15.4909 20.7684 15.2777C20.9294 15.0411 21.0103 14.7592 20.999 14.4727V11.292H17.7638ZM15.1424 9.25715C15.3571 9.25867 15.5702 9.21558 15.7683 9.13092C16.1553 8.96614 16.4629 8.6585 16.627 8.27149C16.7948 7.87087 16.7948 7.41962 16.6262 7.01824C16.4614 6.63123 16.1538 6.32284 15.7668 6.15881C15.5687 6.07415 15.3556 6.03107 15.1401 6.03258C14.9262 6.03107 14.7146 6.07415 14.5181 6.15881C14.3714 6.22155 14.3253 6.30318 14.1235 6.40145V8.88829C14.3261 8.98731 14.3714 9.06818 14.5181 9.13092C14.7153 9.21634 14.9277 9.25867 15.1424 9.25715ZM14.1235 17.4289C14.3261 17.4728 14.3986 17.5068 14.5422 17.5265C14.6995 17.5491 14.8582 17.5605 15.0169 17.5612C15.2618 17.5597 15.5067 17.534 15.7464 17.4826C15.9905 17.4335 16.2248 17.342 16.438 17.2128C16.6474 17.0843 16.8235 16.9089 16.9535 16.701C17.0971 16.4584 17.1674 16.1787 17.1568 15.8968V10.2806H14.1235V17.4289ZM3 18.977L13.5165 20.7971V3L3 4.82015V18.977Z"
					fill="#5C6BC0"
				/>
				<path
					d="M10.6849 8.46039L5.83142 8.75594V9.87539L7.65157 9.80207V14.9927L8.86476 15.0637V9.75445L10.6849 9.67357V8.46039Z"
					fill="white"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default MicrosoftTeamsIconBuilder;

