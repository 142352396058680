import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



export enum LookerStudioIconVariants {
	Active = 'active',
	Inactive = 'inactive',
}



type LookerStudioIconBuilderProps = {
	variant?: LookerStudioIconVariants,
};

const LookerStudioIconBuilder: IconBuilder<LookerStudioIconBuilderProps> = {
	icon: (props) => {
		const {
			variant = LookerStudioIconVariants.Active,
		} = props;

		if (variant === LookerStudioIconVariants.Inactive) {
			return (
				<g
					fill="none"
					fillRule="evenodd"
					stroke="none"
					strokeWidth="1"
				>
					<path
						d="M12.1,1.2c-1.1,0-1.9,0.8-1.9,1.9c0,0.4,0.1,0.7,0.4,1.1l0.8-0.8V3.1c0-0.5,0.4-0.8,0.8-0.8
	c0.4,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8H12l-0.8,0.7c0.9,0.5,2.1,0.4,2.6-0.5c0.5-0.9,0.4-2.1-0.5-2.6
	C13,1.3,12.6,1.2,12.1,1.2L12.1,1.2z"
						fill="#BEC5CB"
					/>
					<path
						d="M11.4,6.7c0-0.6-0.2-1.3-0.5-1.8L9.8,6C9.9,6.2,10,6.4,10,6.7c0,0.5-0.2,0.8-0.5,1.1l0.5,1.4
	C11,8.7,11.4,7.7,11.4,6.7L11.4,6.7z"
						fill="#ACB5C0"
					/>
					<path
						d="M8.5,8.2C7.7,8.2,7,7.6,7,6.8C7,6,7.6,5.3,8.5,5.3c0.3,0,0.6,0.1,0.9,0.3l1.1-1C9.8,4,9.2,3.7,8.5,3.7
	c-1.6,0-3,1.4-3,3c0,1.6,1.3,3,3,3c0.2,0,0.5,0,0.6-0.1L8.5,8.2L8.5,8.2z"
						fill="#ACB5C0"
					/>
					<path
						d="M12.2,9.4c-0.6,0-1.3,0.1-1.9,0.3l0.8,2c0.4,0,0.7-0.1,1.1-0.1c2.5,0,4.5,2.1,4.5,4.5s-2.1,4.5-4.5,4.5
	c-2.4,0-4.5-2.1-4.5-4.5c0-1.7,0.9-3.2,2.4-4l-0.8-2C6,11.7,4.7,15.8,6.3,19c1.6,3.3,5.7,4.7,8.9,3.1c3.3-1.6,4.7-5.7,3.1-8.9
	C17.1,10.9,14.8,9.4,12.2,9.4L12.2,9.4z"
						fill="#96A4B4"
					/>
				</g>
			);
		}

		return (
			<g
				fill="none"
				fillRule="evenodd"
				stroke="none"
				strokeWidth="1"
			>
				<path
					d="M12.1,1.2c-1.1,0-1.9,0.8-1.9,1.9c0,0.4,0.1,0.7,0.4,1.1l0.8-0.8V3.1c0-0.5,0.4-0.8,0.8-0.8
	c0.4,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8H12l-0.8,0.7c0.9,0.5,2.1,0.4,2.6-0.5c0.5-0.9,0.4-2.1-0.5-2.6
	C13,1.3,12.6,1.2,12.1,1.2L12.1,1.2z"
					fill="#AECBFA"
				/>
				<path
					d="M11.4,6.7c0-0.6-0.2-1.3-0.5-1.8L9.8,6C9.9,6.2,10,6.4,10,6.7c0,0.5-0.2,0.8-0.5,1.1l0.5,1.4
	C11,8.7,11.4,7.7,11.4,6.7L11.4,6.7z"
					fill="#5E97F6"
				/>
				<path
					d="M8.5,8.2C7.7,8.2,7,7.6,7,6.8C7,6,7.6,5.3,8.5,5.3c0.3,0,0.6,0.1,0.9,0.3l1.1-1C9.8,4,9.2,3.7,8.5,3.7
	c-1.6,0-3,1.4-3,3c0,1.6,1.3,3,3,3c0.2,0,0.5,0,0.6-0.1L8.5,8.2L8.5,8.2z"
					fill="#5E97F6"
				/>
				<path
					d="M12.2,9.4c-0.6,0-1.3,0.1-1.9,0.3l0.8,2c0.4,0,0.7-0.1,1.1-0.1c2.5,0,4.5,2.1,4.5,4.5s-2.1,4.5-4.5,4.5
	c-2.4,0-4.5-2.1-4.5-4.5c0-1.7,0.9-3.2,2.4-4l-0.8-2C6,11.7,4.7,15.8,6.3,19c1.6,3.3,5.7,4.7,8.9,3.1c3.3-1.6,4.7-5.7,3.1-8.9
	C17.1,10.9,14.8,9.4,12.2,9.4L12.2,9.4z"
					fill="#4285F4"
				/>
			</g>
		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default LookerStudioIconBuilder;

