import useEmblaCarousel from 'embla-carousel-react';
import React, {
	useEffect,
} from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import AttachedEnterpriseRibbon from '~/components/patterns/pricingPlans/AttachedEnterpriseRibbon';
import BenefitNameWithIcon from './BenefitNameWithIcon';
import BenefitSectionName from './BenefitSectionName';
import Caption from '~/components/patterns/headings/Caption';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import PlanBox from '~/components/patterns/pricingPlans/PlanBox';
import PlanDetails from '~/components/patterns/pricingPlans/PlanDetails';
import PlanFeatureCell from '~/components/patterns/pricingPlans/PlanFeatureCell';
import PlanName from '../../../names/PlanName';
import PlanPriceSummary from '../components/PlanPriceSummary';
import SignupPlanComparisonButton from '~/components/app/SignupPlanComparisonButton';
import UnderlinedRows, {
	UnderlinedRowsStyle,
} from '~/components/patterns/lists/UnderlinedRows';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountId from '~/hooks/useAccountId';

import {
	type BenefitSection,
} from '~/model/benefits';

import {
	getNewPlanBenefits,
} from '~/model/universal';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import {
	isEnterprisePlan,
} from '~/model/plans';

import {
	isString,
} from '~/utilities/typeCheck';



const ContainerStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	gap: 10,
};

const SlideStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	flexShrink: 0,
	gap: 10,
	maxWidth: 350,
	width: '82%',
};

const messages = defineMessages({
	mostPopular: {
		id: 'ui.planBenefits.flags.mostPopular',
	},
});



type Props = {
	disabledPlans: Partial<Record<GraphQL.AccountPlan, 'accountTooLarge' | 'billingCycleUnavailable' | 'disallowed' | undefined>>,
	focusedPlan?: GraphQL.AccountPlan,
	mostPopularPlan: GraphQL.AccountPlan | null,
	plans: ReadonlyArray<GraphQL.AccountPlan>,
	sections: Array<BenefitSection>,
	tariff: Tariff,
};

const MobilePlansComparison = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		disabledPlans,
		focusedPlan,
		mostPopularPlan,
		plans,
		sections,
		tariff,
	} = props;

	const accountId = useAccountId();
	const accountFeaturesAvailability = useAccountFeaturesAvailability();

	const initialFocusedPlan = React.useRef(focusedPlan ? plans.indexOf(focusedPlan) : 0);

	const [emblaRef, emblaApi] = useEmblaCarousel(
		{
			align: 'center',
			containScroll: false,
			startIndex: initialFocusedPlan.current,
		},
	);

	useEffect(
		() => {
			if (focusedPlan === undefined) {
				return;
			}

			emblaApi?.scrollTo(plans.indexOf(focusedPlan), false);
		},
		[
			emblaApi,
			focusedPlan,
			plans,
		],
	);

	if (accountFeaturesAvailability === null) {
		return null;
	}

	return (
		<List
			ref={ref}
			size={ListSize.XLarge}
		>
			<div ref={emblaRef}>
				<div style={ContainerStyle}>
					{plans.map((planType, i) => {
						const isDisabled = isString(disabledPlans[planType]);
						const isEnterprise = isEnterprisePlan(planType);

						return (
							<div
								key={'overview-plan-' + i}
								style={SlideStyle}
							>
								<PlanBox
									details={(
										<PlanDetails
											ctaButton={(
												<SignupPlanComparisonButton plan={planType} />
											)}
											isDisabled={isDisabled}
											name={(
												isEnterprise ? (
													<AttachedEnterpriseRibbon>
														<PlanName plan={planType} />
													</AttachedEnterpriseRibbon>
												) : (
													<PlanName plan={planType} />
												)
											)}
											priceOverview={accountId !== null && (
												<PlanPriceSummary
													accountId={accountId}
													disabledReason={disabledPlans[planType]}
													plan={planType}
												/>
											)}
										/>
									)}
									flag={planType === mostPopularPlan && (
										<FormattedMessage {...messages.mostPopular} />
									)}
									isDisabled={isDisabled}
									isEnterprise={isEnterprise}
								/>

								{sections.map((section) => {
									const sectionPlanBenefits = getNewPlanBenefits(tariff, accountFeaturesAvailability, section);

									return (
										<>
											<Caption>
												<BenefitSectionName
													section={section}
												/>
											</Caption>

											<PlanBox
												benefits={(
													<UnderlinedRows
														style={(
															isEnterprise
																? UnderlinedRowsStyle.Light
																: UnderlinedRowsStyle.Default
														)}
													>
														{sectionPlanBenefits.map((item) => (
															<PlanFeatureCell
																key={`${section}-${item.name}`}
																label={(
																	<BenefitNameWithIcon
																		benefit={item.name}
																		hint={item.hint}
																		iconType={item.iconType}
																		isEnterprise={isEnterprise}
																	/>
																)}
																value={item.values[planType]}
															/>
														))}
													</UnderlinedRows>
												)}
												isDisabled={isDisabled}
												isEnterprise={isEnterprise}
												title={isEnterprise ? (
													<AttachedEnterpriseRibbon>
														<PlanName plan={planType} />
													</AttachedEnterpriseRibbon>
												) : (
													<PlanName plan={planType} />
												)}
											/>
										</>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</List>
	);
});



export default MobilePlansComparison;
