import Immutable from 'immutable';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import BarChartLabels from '~/components/atoms/charts/components/BarChartLabels';
import ChartLayout from '~/components/patterns/charts/layouts/ChartLayout';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import IssueCategoryTitle from '~/components/names/IssueCategoryTitle';
import StackedBarChart from '~/components/atoms/charts/charts/StackedBarChart';

import useFilteredWebsiteScopeIssues from '~/hooks/useFilteredWebsiteScopeIssues';
import useViewportWidth from '~/hooks/useViewportWidth';

import {
	openCategoryAffectedPages,
} from '~/actions/issuesScreen';

import {
	ISSUE_PSEUDO_STATE_IGNORED,
	ISSUE_STATE_CLOSED,
	ISSUE_STATE_NOT_APPLICABLE,
	ISSUE_STATE_NOT_REQUIRED,
	ISSUE_STATE_OPEN,
	ISSUE_STATE_UNKNOWN,
} from '~/model/issues';

import {
	hasAffectedPagesPerState,
} from '~/model/issuesNew';



const messages = defineMessages({
	chartTitle: {
		id: 'ui.issuesOverview.charts.affectedPagesPerCategoryChart.title',
	},
	[ISSUE_PSEUDO_STATE_IGNORED]: {
		id: 'ui.issueDetail.affectedPagesChart.ignoredOnWebsite',
	},
	[ISSUE_STATE_CLOSED]: {
		id: 'ui.issueDetail.affectedPagesChart.notPresentOnWebsite',
	},
	[ISSUE_STATE_NOT_APPLICABLE]: {
		id: 'ui.issueDetail.affectedPagesChart.notApplicableOnWebsite',
	},
	[ISSUE_STATE_NOT_REQUIRED]: {
		id: 'ui.issueDetail.affectedPagesChart.notRequiredOnWebsite',
	},
	[ISSUE_STATE_OPEN]: {
		id: 'ui.issueDetail.affectedPagesChart.presentOnWebsite',
	},
	[ISSUE_STATE_UNKNOWN]: {
		id: 'ui.issueDetail.affectedPagesChart.unknownOnWebsite',
	},
});



type Props = {
	websiteId: CK.WebsiteId | null,
};

const AffectedPagesPerCategoryChart: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const dispatch = useDispatch();
	const intl = useIntl();
	const viewportWidth = useViewportWidth();
	const issueCategories = useFilteredWebsiteScopeIssues(websiteId);

	const handleOpenBarClick = React.useCallback(
		({ category }) => {
			dispatch(
				openCategoryAffectedPages(
					category,
				),
			);
		},
		[
			dispatch,
		],
	);

	const renderLabel = React.useCallback(
		(labelKey) => {
			return (
				<Ellipsis maxWidth={160}>
					<IssueCategoryTitle
						issueCategoryName={labelKey}
					/>
				</Ellipsis>
			);
		},
		[],
	);

	const renderTooltip = React.useCallback(
		(formatter) => {
			return intl.formatMessage(messages[formatter.series.name], {
				issues: 999,
				pages: formatter.y / 100,
			});
		},
		[
			intl,
		],
	);

	if (issueCategories === null || issueCategories.length === 0) {
		return null;
	}

	const order = [
		'open',
		'ignored',
		'closed',
		'unknown',
		'notRequired',
		'notApplicable',
	] as const;

	const pagesIssues = issueCategories
		.filter(hasAffectedPagesPerState)
		.sort((categoryA, categoryB) => {
			const percentagesA = categoryA.affectedPagesPerState.percentage;
			const percentagesB = categoryB.affectedPagesPerState.percentage;

			for (const group of order) {
				if (percentagesA[group] !== percentagesB[group]) {
					return percentagesA[group] > percentagesB[group] ? -1 : 1;
				}
			}

			return categoryA.name < categoryB.name ? -1 : 1;
		});

	const categories = pagesIssues.map(
		(issueCategory) => issueCategory.name,
	);

	const chartData = Immutable.List([{
		name: ISSUE_STATE_NOT_APPLICABLE,
		data: pagesIssues.map(
			(issueCategory) => ({
				color: 'rgba(0,0,0,0.05)',
				y: issueCategory.affectedPagesPerState.percentage.notApplicable,
			}),
		),
	}, {
		name: ISSUE_STATE_NOT_REQUIRED,
		data: pagesIssues.map(
			(issueCategory) => ({
				color: 'rgba(0,0,0,0.05)',
				y: issueCategory.affectedPagesPerState.percentage.notRequired,
			}),
		),
	}, {
		name: ISSUE_STATE_UNKNOWN,
		data: pagesIssues.map(
			(issueCategory) => ({
				color: 'rgba(0,0,0,0.05)',
				y: issueCategory.affectedPagesPerState.percentage.unknown,
			}),
		),
	}, {
		name: ISSUE_STATE_CLOSED,
		color: '#42CC67',
		data: pagesIssues.map(
			(issueCategory) => ({
				y: issueCategory.affectedPagesPerState.percentage.closed,
			}),
		),
	}, {
		name: ISSUE_PSEUDO_STATE_IGNORED,
		color: '#98A5B3',
		data: pagesIssues.map(
			(issueCategory) => ({
				y: issueCategory.affectedPagesPerState.percentage.ignored,
			}),
		),
	}, {
		name: ISSUE_STATE_OPEN,
		color: '#FF5959',
		data: pagesIssues.map(
			(issueCategory) => ({
				y: issueCategory.affectedPagesPerState.percentage.open,
			}),
		),
		cursor: 'pointer',
		point: {
			events: {
				click: function () {
					handleOpenBarClick({
						category: this.category,
					});
				},
			},
		},
	}]);

	return (
		<ChartLayout
			label={(
				<FormattedMessage {...messages.chartTitle} />
			)}
		>
			<BarChartLabels
				categories={categories}
				isXAxisVisible={true}
				labelRenderer={renderLabel}
			>
				<StackedBarChart
					categories={categories}
					data={chartData}
					key={viewportWidth}
					maximum={100}
					showXAxis={true}
					tooltipRenderer={renderTooltip}
				/>
			</BarChartLabels>
		</ChartLayout>
	);
};



export default AffectedPagesPerCategoryChart;
