import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	NotApplicable,
} from '~/components/atoms/forms/basis/Form';

import isDomain from '~/utilities/isDomain';
import isDomainPattern from '~/utilities/isDomainPattern';
import isEmail from '~/utilities/isEmail';
import isValidRegexp from '~/utilities/isValidRegexp';

import {
	renderProp,
} from '~/utilities/renderProp';

import {
	isString,
} from '~/utilities/typeCheck';

import {
	type Message,
	type Rule,
	type Values,
} from '~/utilities/validations';



const messages = defineMessages({
	empty: {
		id: 'ui.formErrors.blank',
	},
	invalidBoolean: {
		id: 'ui.formErrors.invalidBoolean',
	},
	invalidDomain: {
		id: 'ui.formErrors.invalidDomain',
	},
	invalidEmail: {
		id: 'ui.formErrors.invalidEmail',
	},
	invalidInput: {
		id: 'ui.formErrors.general',
	},
	invalidJson: {
		id: 'ui.formErrors.invalidJson',
	},
	invalidRegexp: {
		id: 'ui.formErrors.invalidRegexp',
	},
	mustBeChecked: {
		id: 'ui.formErrors.invalidInput',
	},
	tooLong: {
		id: 'ui.formErrors.maxChars',
	},
	tooLarge: {
		id: 'ui.formErrors.maxValue',
	},
	tooShort: {
		id: 'ui.formErrors.minChars',
	},
	tooSmall: {
		id: 'ui.formErrors.minValue',
	},
});



class ValidationBuilder {

	private field: string;
	private extraFields: Array<string>;
	private valueSelector?: (values: Values) => any;

	constructor(field: string, extraFields: Array<string> = []) {
		this.field = field;
		this.extraFields = extraFields;
	}

	public setValueSelector(valueSelector: (values: Values) => any): void {
		this.valueSelector = valueSelector;
	}

	private createRule({ message, rule }): Rule {
		const result = {
			message: (input) => {
				return renderProp(message, {
					...input,
					value: this.valueSelector ? this.valueSelector(input.values) : input.values[this.field],
				});
			},
			rule: ({ values }) => {
				return rule({
					values,
					value: this.valueSelector ? this.valueSelector(values) : values[this.field],
				});
			},
		};

		if (this.extraFields.length > 0) {
			return {
				...result,
				fields: [this.field, ...this.extraFields],
			};
		}

		return {
			...result,
			field: this.field,
		};
	}

	private createGlobalRule({ field, globalRule, message }): Rule {
		return {
			field: field ?? this.field,
			globalRule,
			message,
		};
	}

	custom({ message, rule }): Rule {
		return this.createRule({
			message,
			rule,
		});
	}

	customGlobal({
		field = null,
		globalRule,
		message,
	}: {
		field?: string | null,
		globalRule: string,
		message: Message<{ globalError: any }>,
	}): Rule {
		return this.createGlobalRule({
			field,
			globalRule,
			message,
		});
	}

	validateBoolean(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidBoolean} />
			),
			rule: ({ value }) => {
				return value === true || value === false;
			},
		});
	}

	validateChecked(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.mustBeChecked} />
			),
			rule: ({ value }) => {
				return value === true;
			},
		});
	}

	validateDomain(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidDomain} />
			),
			rule: ({ value }) => {
				return isDomain(value);
			},
		});
	}

	validateDomainPatterns(entity: string): Array<Rule> {
		return [
			this.createRule({
				message: `No ${entity} provided`,
				rule: ({ value }) => {
					return (value ?? []).length > 0;
				},
			}),
			this.createRule({
				message: `Some ${entity} are invalid`,
				rule: ({ value }) => {
					return value.every(isDomainPattern);
				},
			}),
		];
	}

	validateEmail(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidEmail} />
			),
			rule: ({ value }) => {
				return isEmail(value);
			},
		});
	}

	validateEmails(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidEmail} />
			),
			rule: ({ value }) => {
				if (typeof value !== 'string') {
					return false;
				}

				const emails = value.split(',');
				const validEmails = emails.filter((email) => isEmail(email.trim()));

				return (
					validEmails.length > 0
					&& emails.length === validEmails.length
				);
			},
		});
	}

	validateEmailPatterns() {
		return [
			this.createRule({
				message: `No emails or email domains provided`,
				rule: ({ value }) => {
					return (value ?? []).length > 0;
				},
			}),
			this.createRule({
				message: `Some emails or email domains are invalid`,
				rule: ({ value }) => {
					return value.every((currentValue) => {
						return isEmail(currentValue) || isDomainPattern(currentValue);
					});
				},
			}),
		];
	}

	validateInteger(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidInput} />
			),
			rule: ({ value }) => {
				return /^\d+$/.test(value);
			},
		});
	}

	validateJson(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidJson} />
			),
			rule: ({ value }) => {
				if (isString(value) === false) {
					return false;
				}

				try {
					JSON.parse(value);
				} catch (error) {
					console.info('Invalid JSON', error);

					return false;
				}

				return true;
			},
		});
	}

	validateMaximumLength(maximumLength: number): Rule {
		return this.createRule({
			message: (
				<FormattedMessage
					{...messages.tooLong}
					values={{
						maxCharacters: maximumLength,
					}}
				/>
			),
			rule: ({ value }) => {
				return value.length <= maximumLength;
			},
		});
	}

	validateMaximumValue(maximumValue: number): Rule {
		return this.createRule({
			message: (
				<FormattedMessage
					{...messages.tooLarge}
					values={{
						maxValue: maximumValue,
					}}
				/>
			),
			rule: ({ value }) => {
				return value <= maximumValue;
			},
		});
	}

	validateMinimumLength(minimumLength: number): Rule {
		return this.createRule({
			message: (
				<FormattedMessage
					{...messages.tooShort}
					values={{
						minCharacters: minimumLength,
					}}
				/>
			),
			rule: ({ value }) => {
				return value.length >= minimumLength;
			},
		});
	}

	validateMinimumValue(minimumValue: number): Rule {
		return this.createRule({
			message: (
				<FormattedMessage
					{...messages.tooSmall}
					values={{
						minValue: minimumValue,
					}}
				/>
			),
			rule: ({ value }) => {
				return value >= minimumValue;
			},
		});
	}

	validateNonEmpty(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.empty} />
			),
			rule: ({ value }) => {
				if (typeof value !== 'string') {
					return false;
				}

				return value.length > 0;
			},
		});
	}

	validateNumber(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidInput} />
			),
			rule: ({ value }) => {
				return !isNaN(value) && !isNaN(parseFloat(value));
			},
		});
	}

	validateRegexp(): Rule {
		return this.createRule({
			message: (
				<FormattedMessage {...messages.invalidRegexp} />
			),
			rule: ({ value }) => {
				return isString(value) && isValidRegexp(value);
			},
		});
	}

	whenNonEmpty(): Rule {
		return this.createRule({
			message: null,
			rule: ({ value }) => {
				return !!value || NotApplicable;
			},
		});
	}

	whenOtherField(otherField: string, rule: (input: { value: any, values: Values }) => boolean): Rule {
		return {
			message: null,
			fields: [this.field, ...this.extraFields, otherField],
			rule: ({ values }) => {
				return rule({ value: values[otherField], values }) ? true : NotApplicable;
			},
		};
	}

	when(rule: (input: { values: Values }) => boolean): Rule {
		return {
			message: null,
			fields: [this.field, ...this.extraFields],
			rule: ({ values }) => {
				return rule({ values }) ? true : NotApplicable;
			},
		};
	}

}



export function validateField(
	field: string,
	rules: ValidationFactory,
) {
	const f = new ValidationBuilder(field);

	let result: Array<Rule> = [];

	rules(f).forEach((rules) => {
		const appliedRules = rules instanceof Array
			? rules
			: [rules];

		result = [
			...result,
			...appliedRules,
		];
	});

	return result;
}



export function validateFields(
	fields: Array<string>,
	rules: Record<string, ValidationFactory>,
) {
	let result: Array<Rule> = [];

	for (const [field, rule] of Object.entries(rules)) {
		const f = new ValidationBuilder(
			field,
			fields.filter((otherField) => otherField !== field),
		);

		rule(f).forEach((rules) => {
			const appliedRules = rules instanceof Array
				? rules
				: [rules];

			result = [
				...result,
				...appliedRules,
			];
		});
	}

	return result;
}



export function validateWhenField(
	field: string,
	dependency: (input: { value: any }) => boolean,
	rules: Record<string, Array<Rule>>,
): Record<string, Array<Rule>> {
	const result: Record<string, Array<Rule>> = {};

	for (const [thread, threadRules] of Object.entries(rules)) {
		result[thread] = [
			{
				message: null,
				field,
				rule: ({ values }) => {
					return dependency({ value: values[field] }) ? true : NotApplicable;
				},
			},
			...threadRules,
		];
	}

	return result;
}



export type {
	ValidationBuilder,
};



export type ValidationFactory = (f: ValidationBuilder) => ReadonlyArray<Rule | ReadonlyArray<Rule>>;
