import React from 'react';

import DefaultFilterValueHandler from '~/components/logic/datatables/DefaultFilterValueHandler';
import MultiselectField, {
	type MultiselectFieldRef,
	MultiselectFieldSize,
} from '~/components/atoms/forms/components/MultiselectField';



type Props = Omit<React.ComponentProps<typeof MultiselectField>, 'options'> & {
	allValue?: string,
	formatSelectedValues?: (value: any) => any,
	options: ReadonlyArray<{
		name: string,
		suffix?: React.ReactNode,
		title: React.ReactNode,
	} | {
		divider: boolean,
	}>,
};

const MultiselectFieldFilter = React.forwardRef<MultiselectFieldRef, Props>((props, ref) => {
	const {
		allValue,
		dropdownInnerPanelWidth,
		dropdownWidth = null,
		formatSelectedValues,
		hideInnerOptions,
		isOnlyLinkVisible,
		labelRenderer,
		name,
		options,
		scrollableDropdown = false,
		selectedLabelRenderer,
		visibleOptionsCount,
		width,
	} = props;

	const fieldRef = React.useRef<MultiselectFieldRef>(null);

	const convertValueIn = React.useCallback(
		(value) => {
			if (allValue !== undefined && value === allValue) {
				return options
					.filter((option) => 'name' in option)
					.map((option) => option.name);
			}

			return value;
		},
		[
			allValue,
			options,
		],
	);

	const convertValueOut = React.useCallback(
		(value) => {
			if (allValue !== undefined) {
				const namedOptions = options.filter((option) => 'name' in option);

				if (value.length === namedOptions) {
					return allValue;
				}
			}

			return value;
		},
		[
			allValue,
			options,
		],
	);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			if (formatSelectedValues !== undefined) {
				value = formatSelectedValues(value);
			}

			fieldRef.current?.changeValue(
				convertValueIn(value),
			);
		},
		close: () => fieldRef.current?.close(),
		open: () => fieldRef.current?.open(),
	}));

	const finalDropdownWidth = dropdownWidth === null
		? width
		: Math.max(dropdownWidth, width ?? 0);

	return (
		<DefaultFilterValueHandler
			convertIn={convertValueIn}
			convertOut={convertValueOut}
			name={name}
		>
			<MultiselectField
				dropdownInnerPanelWidth={dropdownInnerPanelWidth}
				dropdownWidth={finalDropdownWidth}
				hideInnerOptions={hideInnerOptions}
				isOnlyLinkVisible={isOnlyLinkVisible}
				labelRenderer={labelRenderer}
				name={name}
				options={options}
				popperEnabled={false}
				ref={fieldRef}
				scrollableDropdown={scrollableDropdown}
				selectedLabelRenderer={selectedLabelRenderer}
				size={MultiselectFieldSize.Small}
				visibleOptionsCount={visibleOptionsCount}
				width={width}
			/>
		</DefaultFilterValueHandler>
	);
});



export default MultiselectFieldFilter;

export {
	MultiselectFieldRef as MultiselectFieldFilterRef,
};
