/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InterfaceHintsQueryVariables = GraphQL.Exact<{
  userId: GraphQL.Scalars['UserId']['input'];
}>;


export type InterfaceHintsQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly interfaceHints: ReadonlyArray<{ readonly __typename?: 'InterfaceHintStatus', readonly displayed: boolean, readonly name: GraphQL.InterfaceHint }> | null } | null };

export type RememberDisplayedInterfaceHintsMutationVariables = GraphQL.Exact<{
  email: GraphQL.Scalars['String']['input'];
  interfaceHints: ReadonlyArray<GraphQL.InterfaceHint> | GraphQL.InterfaceHint;
  userId: GraphQL.Scalars['UserId']['input'];
}>;


export type RememberDisplayedInterfaceHintsMutation = { readonly __typename?: 'Mutation', readonly RememberDisplayedInterfaceHints: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly interfaceHints: ReadonlyArray<{ readonly __typename?: 'InterfaceHintStatus', readonly displayed: boolean, readonly name: GraphQL.InterfaceHint }> | null } | null } } };


export const InterfaceHintsDocument = gql`
    query InterfaceHints($userId: UserId!) {
  user(uniqueId: $userId) {
    id
    legacyId
    interfaceHints {
      displayed
      name
    }
  }
}
    `;

/**
 * __useInterfaceHintsQuery__
 *
 * To run a query within a React component, call `useInterfaceHintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterfaceHintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterfaceHintsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInterfaceHintsQuery(baseOptions: Apollo.QueryHookOptions<InterfaceHintsQuery, InterfaceHintsQueryVariables> & ({ variables: InterfaceHintsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterfaceHintsQuery, InterfaceHintsQueryVariables>(InterfaceHintsDocument, options);
      }
export function useInterfaceHintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterfaceHintsQuery, InterfaceHintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterfaceHintsQuery, InterfaceHintsQueryVariables>(InterfaceHintsDocument, options);
        }
export function useInterfaceHintsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InterfaceHintsQuery, InterfaceHintsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InterfaceHintsQuery, InterfaceHintsQueryVariables>(InterfaceHintsDocument, options);
        }
export type InterfaceHintsQueryHookResult = ReturnType<typeof useInterfaceHintsQuery>;
export type InterfaceHintsLazyQueryHookResult = ReturnType<typeof useInterfaceHintsLazyQuery>;
export type InterfaceHintsSuspenseQueryHookResult = ReturnType<typeof useInterfaceHintsSuspenseQuery>;
export type InterfaceHintsQueryResult = Apollo.QueryResult<InterfaceHintsQuery, InterfaceHintsQueryVariables>;
export const RememberDisplayedInterfaceHintsDocument = gql`
    mutation RememberDisplayedInterfaceHints($email: String!, $interfaceHints: [InterfaceHint!]!, $userId: UserId!) {
  RememberDisplayedInterfaceHints(email: $email, interfaceHints: $interfaceHints) {
    query {
      user(uniqueId: $userId) {
        id
        legacyId
        interfaceHints {
          displayed
          name
        }
      }
    }
  }
}
    `;
export type RememberDisplayedInterfaceHintsMutationFn = Apollo.MutationFunction<RememberDisplayedInterfaceHintsMutation, RememberDisplayedInterfaceHintsMutationVariables>;

/**
 * __useRememberDisplayedInterfaceHintsMutation__
 *
 * To run a mutation, you first call `useRememberDisplayedInterfaceHintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRememberDisplayedInterfaceHintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rememberDisplayedInterfaceHintsMutation, { data, loading, error }] = useRememberDisplayedInterfaceHintsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      interfaceHints: // value for 'interfaceHints'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRememberDisplayedInterfaceHintsMutation(baseOptions?: Apollo.MutationHookOptions<RememberDisplayedInterfaceHintsMutation, RememberDisplayedInterfaceHintsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RememberDisplayedInterfaceHintsMutation, RememberDisplayedInterfaceHintsMutationVariables>(RememberDisplayedInterfaceHintsDocument, options);
      }
export type RememberDisplayedInterfaceHintsMutationHookResult = ReturnType<typeof useRememberDisplayedInterfaceHintsMutation>;
export type RememberDisplayedInterfaceHintsMutationResult = Apollo.MutationResult<RememberDisplayedInterfaceHintsMutation>;
export type RememberDisplayedInterfaceHintsMutationOptions = Apollo.BaseMutationOptions<RememberDisplayedInterfaceHintsMutation, RememberDisplayedInterfaceHintsMutationVariables>;