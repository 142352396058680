import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnsSetsNavigation from '~/components/patterns/columnsConfigurator/ColumnsSetsNavigation';

import useChangeColumnSet from '~/hooks/useChangeColumnSet';
import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useUiInteractions, {
	UiInteraction,
} from '~/hooks/useUiInteractions';

import {
	toggleColumnsSetsList,
} from '~/actions';

import matchAndReturn from '~/utilities/matchAndReturn';



enum Type {
	Personal = 'personal',
	Shared = 'shared',
}

const messages = defineMessages({
	[Type.Personal]: {
		id: 'ui.columnsConfigurator.heading.personal',
	},
	[Type.Shared]: {
		id: 'ui.columnsConfigurator.heading.shared',
	},
	edit: {
		id: 'ui.forms.editLink',
	},
});

function sortSets(
	setA: {
		name: string,
	},
	setB: {
		name: string,
	},
) {
	const setNameA = setA.name.toLowerCase();
	const setNameB = setB.name.toLowerCase();

	if (setNameA < setNameB) {
		return -1;
	}

	if (setNameA > setNameB) {
		return 1;
	}

	return 0;
}



const ColumnSetsList: React.FC = () => {
	const dispatch = useDispatch();
	const intl = useIntl();

	const markUiInteraction = useUiInteractions((store) => store.markInteraction);

	const changeColumnSet = useChangeColumnSet();
	const columnSets = useColumnSets();
	const currentColumnSet = useCurrentColumnSet();

	const currentColumnSetId = currentColumnSet?.id ?? null;

	const handleColumnSetNavigate = React.useCallback(
		(columnSetId: CK.ColumnSetId) => {
			if (currentColumnSetId !== columnSetId) {
				changeColumnSet(columnSetId, true);
			}
		},
		[
			changeColumnSet,
			currentColumnSetId,
		],
	);

	const handleColumnSetEdit = React.useCallback(
		(columnSetId: CK.ColumnSetId) => {
			if (currentColumnSetId !== columnSetId) {
				changeColumnSet(columnSetId, false);
			} else {
				markUiInteraction(UiInteraction.ColumnsSetsListOpen);
				dispatch(toggleColumnsSetsList(false));
			}
		},
		[
			changeColumnSet,
			currentColumnSetId,
			dispatch,
			markUiInteraction,
		],
	);

	function renderGroup(
		type: Type,
		columnSets: ReadonlyArray<{
			id: CK.ColumnSetId,
			name: string,
		}>,
	) {
		return (
			<ColumnsSetsNavigation
				columnSets={columnSets}
				editMessage={intl.formatMessage(messages.edit)}
				icon={matchAndReturn(type, {
					[Type.Personal]: BasicIconType.Person,
					[Type.Shared]: BasicIconType.Team,
				})}
				onEdit={handleColumnSetEdit}
				onNavigate={handleColumnSetNavigate}
				selectedColumnSetId={currentColumnSetId}
				title={(
					<FormattedMessage {...messages[type]} />
				)}
			/>
		);
	}

	const personalSets = columnSets.columnSets.filter((set) => set.sharedWithAccountId === null).sort(sortSets);
	const sharedSets = columnSets.columnSets.filter((set) => set.sharedWithAccountId !== null).sort(sortSets);

	const output: Array<React.ReactNode> = [];

	if (sharedSets.length > 0) {
		output.push(
			renderGroup(Type.Shared, sharedSets),
		);
	}

	if (personalSets.length > 0) {
		output.push(
			renderGroup(Type.Personal, personalSets),
		);
	}

	return (
		<>{output}</>
	);
};



export default ColumnSetsList;
