import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import DotInterfaceHint from '~/components/logic/DotInterfaceHint';
import {
	UiInteraction,
} from '~/hooks/useUiInteractions';

import useUrlState from '~/hooks/useUrlState';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.percentageOfAffectedPages',
	},
});



type Props = {
	children: React.ReactElement,
};

const PercentageOfAffectedPagesHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const urlState = useUrlState();

	if (urlState.name !== 'website.issues.issueCategory') {
		return children;
	}

	return (
		<DotInterfaceHint
			conditionsAlready={[
				({ uiInteractions }) => uiInteractions[UiInteraction.IssuesCategoryOpen] >= 2,
			]}
			content={(
				<Copy {...messages.message} />
			)}
			iconPosition={{
				left: 223,
				top: 2,
			}}
			name={GraphQL.InterfaceHint.PercentageOfAffectedPages}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(PercentageOfAffectedPagesHint);
