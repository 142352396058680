import type Immutable from 'immutable'
import memoizee from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import BillingCycleField from '~/components/logic/formFields/BillingCycleField';
import BillingCycleName from '~/components/names/BillingCycleName';
import ChangeSummary, {
	PAGES_DIFF_BUNDLE,
} from '~/components/logic/pricing/ChangeSummary';
import PageBundleField from '~/components/app/PageBundleField';
import PlanField from '~/components/app/PlanField';
import PlanName from '~/components/names/PlanName';
import Parenthesis from '~/components/logic/Parenthesis';
import PaymentAuthorizationContext from '~/components/logic/PaymentAuthorizationContext';
import PaymentAuthorizationError from '~/components/logic/PaymentAuthorizationError';
import PaymentCancelButton from '~/components/logic/PaymentCancelButton';
import PaymentSubmitButton from '~/components/logic/PaymentSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useUpdateSubscriptionInLegacyPackageFormMutation,
} from './LegacyPackageForm.gql';

import useAccountBillingCycle from '~/hooks/useAccountBillingCycle';
import useAccountCurrency from '~/hooks/useAccountCurrency';
import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useAccountPaymentStatus from '~/hooks/useAccountPaymentStatus';
import useAccountPlan from '~/hooks/useAccountPlan';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAccountWebsiteSizes from '~/hooks/useAccountWebsiteSizes';
import useAllowedBillingCycles from '~/hooks/useAllowedBillingCycles';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useLegacyBillingDetails from '~/hooks/useLegacyBillingDetails';
import usePageBundle from '~/hooks/usePageBundle';
import useTariffs from '~/hooks/useTariffs';

import {
	type TailoredTariff
} from '~/model/pricing/tailoredTariff';
import {
	DETAIL_PLAN,
} from '~/model/pricing/universal';



const messages = defineMessages({
	agencyPlanLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.plan',
	},
	billingCycleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.billingCycle',
	},
	cannotSubmitDueToPaymentIssues: {
		id: 'ui.subscriptionForm.cannotSubmitDueToPaymentIssues',
	},
	contactBoxTitle: {
		id: 'ui.billing.needHelpPickingPlan.header',
	},
	downgradeBoxDescription: {
		id: 'ui.downgradeBox.message',
	},
	downgradeBoxTitle: {
		id: 'ui.downgradeBox.title',
	},
	pageBundleLabel: {
		id: 'ui.teamDetail.billing.agencyPackage.pageBundle',
	},
	saveButton: {
		id: 'ui.teamDetail.billing.package.confirmationButton',
	},
	title: {
		id: 'ui.teamDetail.subscriptionForm.header',
	},
});

const calculatePrice = memoizee((
	plan: GraphQL.AccountPlan,
	billingCycle: GraphQL.Term,
	pageBundle: number,
	tariff: TailoredTariff,
	teamBilling: Immutable.Map<string, any>,
) => {
	const discounts = teamBilling.get('discounts').filter((discount) => discount.get('type') !== 'dollars').toJS();
	const taxRate = teamBilling.get('tax').toJS();

	return tariff.calculatePurchaseCostDetails({
		billingCycle,
		discounts,
		purchases: [
			{
				details: {
					[DETAIL_PLAN]: plan,
				},
				numberOfPages: pageBundle,
			},
		],
		taxRate,
	});
}, {
	length: 5,
});



type Props = {
	accountId: CK.AccountId | null,
	onEnterEditModeCallback: (isEditing: boolean) => void,
	preselectedPlan?: GraphQL.AccountPlan | null,
};

const LegacyPackageForm: React.FC<Props> = (props) => {
	const {
		accountId,
		onEnterEditModeCallback,
		preselectedPlan = null,
	} = props;

	const accountBillingCycle = useAccountBillingCycle(accountId);
	const accountCurrency = useAccountCurrency(accountId);
	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);
	const accountPageBundle = usePageBundle(accountId);
	const accountPaymentStatus = useAccountPaymentStatus(accountId);
	const accountPlan = useAccountPlan(accountId);
	const accountTariff = useAccountTariff(accountId);
	const accountWebsiteSizes = useAccountWebsiteSizes(accountId);
	const allowedBillingCycles = useAllowedBillingCycles(accountId);
	const tariffs = useTariffs();
	const teamBilling = useLegacyBillingDetails(accountId);

	const [updateSubscriptionInLegacyPackageForm] = useUpdateSubscriptionInLegacyPackageFormMutation();

	const isAllowedToSpendMoney = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.SpendMoney,
	);

	const handleSubmit = React.useCallback(
		async ({ values }) => {
			if (accountId === null) {
				throw new Error(`accountId can't be null`);
			}

			if (accountTariff === null) {
				throw new Error(`accountTariff can't be null`);
			}

			await updateSubscriptionInLegacyPackageForm({
				variables: {
					accountId,
					billingCycle: values.billingCycle,
					pageBundle: values.pageBundle,
					plan: values.plan,
					tariff: accountTariff,
				},
			});
		},
		[
			accountId,
			accountTariff,
			updateSubscriptionInLegacyPackageForm,
		],
	);

	if (
		accountBillingCycle === null
		|| accountCurrency === null
		|| accountFeaturesUsage === null
		|| accountPageBundle === null
		|| accountPaymentStatus === null
		|| accountPlan === null
		|| accountWebsiteSizes === null
		|| allowedBillingCycles === null
		|| tariffs.isReady === false
	) {
		return null;
	}

	const pageBundleUsage = accountWebsiteSizes.totalPageCapacity;

	function renderChangesOverview(values) {
		if (
			!teamBilling
			|| !teamBilling.get('details')
			|| teamBilling.get('details').size === 0
			|| !teamBilling.has('discounts')
			|| !teamBilling.has('tax')
			|| accountBillingCycle === null
			|| accountCurrency === null
			|| accountFeaturesUsage === null
			|| accountPageBundle === null
			|| accountPlan === null
			|| accountWebsiteSizes === null
			|| allowedBillingCycles === null
			|| tariffs.isReady === false
		) {
			return null;
		}

		if (
			(!values.plan || (accountPlan === values.plan))
			&& (!values.pageBundle || (accountPageBundle === values.pageBundle))
			&& (!values.billingCycle || (accountBillingCycle === values.billingCycle))
		) {
			return null;
		}

		const priceOld = calculatePrice(
			accountPlan,
			accountBillingCycle,
			accountPageBundle,
			tariffs.tariff,
			teamBilling,
		);

		const priceNew = calculatePrice(
			values.plan || accountPlan,
			values.billingCycle || accountBillingCycle,
			values.pageBundle || accountPageBundle,
			tariffs.tariff,
			teamBilling,
		);

		return (
			<ChangeSummary
				accountId={accountId}
				allowedBillingCycles={allowedBillingCycles}
				billingCycleNew={values.billingCycle}
				billingCycleOld={accountBillingCycle}
				currency={accountCurrency}
				hidePrice={false}
				pagesDiff={PAGES_DIFF_BUNDLE}
				pagesNew={values.pageBundle}
				pagesOld={accountPageBundle}
				planNew={values.plan}
				planOld={accountPlan}
				priceNew={priceNew.subtotal}
				priceOld={priceOld.subtotal}
				taxType={priceNew.tax > 0 ? GraphQL.TaxType.Vat : null}
			/>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToSpendMoney}
			isOpen={preselectedPlan !== null}
			key={accountId}
			onEnterEditModeCallback={onEnterEditModeCallback}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.pageBundleLabel} />
						)}
					>
						<StaticText>
							<FormattedNumber value={pageBundleUsage} />
							&nbsp;/&nbsp;
							<FormattedNumber value={accountPageBundle} />
							&nbsp;
							<span className="percentage">
								<Parenthesis
									content={(
										<FormattedNumber
											maximumFractionDigits={2}
											minimumFractionDigits={1}
											style="percent"
											value={pageBundleUsage / accountPageBundle}
										/>
									)}
								/>
							</span>
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.billingCycleLabel} />
						)}
					>
						<StaticText>
							<BillingCycleName billingCycle={accountBillingCycle} />
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.agencyPlanLabel} />
						)}
					>
						<StaticText>
							<PlanName plan={accountPlan} />
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				<PaymentAuthorizationContext
					accountId={accountId}
					onSubmit={handleSubmit}
					useModal={true}
				>
					{({ formRef, handleFormSuccess }) => accountId !== null ? (
						<Form
							defaultDataHasChanged={preselectedPlan !== null}
							defaultFocus="pageBundle"
							defaultValues={{
								billingCycle: accountBillingCycle,
								pageBundle: accountPageBundle,
								plan: preselectedPlan ?? accountPlan,
							}}
							onSuccess={handleFormSuccess}
							ref={formRef}
						>
							{({ values }) => {
								const isPlanAvailable = accountFeaturesUsage
									.getPlanAvailability(values.plan || accountPlan)
									.isAvailable;

								return (
									<>
										<FormRows>
											<FormRow
												htmlFor="pageBundle"
												label={(
													<FormattedMessage {...messages.pageBundleLabel} />
												)}
											>
												<PageBundleField
													accountId={accountId}
													name="pageBundle"
												/>
											</FormRow>

											<FormRow
												htmlFor="billingCycle"
												label={(
													<FormattedMessage {...messages.billingCycleLabel} />
												)}
											>
												{allowedBillingCycles.length > 1 ? (
													<BillingCycleField
														allowedBillingCycles={allowedBillingCycles}
														name="billingCycle"
													/>
												) : (
													<StaticText>
														<BillingCycleName billingCycle={accountBillingCycle} />
													</StaticText>
												)}
											</FormRow>

											<FormRow
												htmlFor="plan"
												label={(
													<FormattedMessage {...messages.agencyPlanLabel} />
												)}
											>
												{accountPlan !== GraphQL.AccountPlan.Enterprise && (
													<PlanField
														accountId={accountId}
														name="plan"
													/>
												)}

												{accountPlan === GraphQL.AccountPlan.Enterprise && (
													<StaticText>
														<PlanName plan={accountPlan} />
													</StaticText>
												)}
											</FormRow>

											{!isPlanAvailable && (
												<FormRow fullwidth={true}>
													<CalloutMessage
														borders={true}
														message={(
															<FormattedMessage {...messages.downgradeBoxTitle} />
														)}
														status={CalloutMessageStatus.Warning}
													>
														<p>
															<FormattedMessage
																{...messages.downgradeBoxDescription}
																values={{
																	text__pricingPlan: (
																		<PlanName plan={values.plan || accountPlan} />
																	),
																}}
															/>
														</p>
													</CalloutMessage>
												</FormRow>
											)}

											{renderChangesOverview(values)}
										</FormRows>

										<PaymentAuthorizationError />

										<ButtonsLayout>
											<PaymentCancelButton />

											<PaymentSubmitButton
												isDisabled={accountPaymentStatus.isOkay === false}
												tooltip={accountPaymentStatus.isOkay === false && (
													<FormattedMessage {...messages.cannotSubmitDueToPaymentIssues} />
												)}
											>
												<FormattedMessage {...messages.saveButton} />
											</PaymentSubmitButton>
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					) : null}
				</PaymentAuthorizationContext>
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default LegacyPackageForm;
