import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const SchemaOrgIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				stroke={color}
				strokeLinejoin="round"
				strokeWidth={(60 / size) * 2}
			>
				<path
					d="M40.33 48h0a5.94 5.94 0 0 0 5.94-6v-5.69a5.93 5.93 0 0 1 3-5.15l2-1.16-2-1.16a5.93 5.93 0 0 1-3-5.15V18a5.94 5.94 0 0 0-5.94-6h0M19.67 48h0a5.94 5.94 0 0 1-5.94-6v-5.69a5.93 5.93 0 0 0-3-5.15L8.75 30l2-1.16a5.93 5.93 0 0 0 3-5.15V18a5.94 5.94 0 0 1 5.92-6h0"
					strokeLinecap="round"
				/>
				<circle
					cx="29.7"
					cy="32.14"
					r=".99"
				/>
				<circle
					cx="29.7"
					cy="24.99"
					r=".99"
				/>
				<path
					d="M30,31.2c2.9.78,2.17,8.51-2.38,9.42"
					strokeLinecap="round"
				/>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default SchemaOrgIconBuilder;

