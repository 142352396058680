import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import DotInterfaceHint from '~/components/logic/DotInterfaceHint';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.relevance',
	},
});



type Props = {
	children: React.ReactNode,
};

const RelevanceHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<DotInterfaceHint
			content={(
				<Copy {...messages.message} />
			)}
			iconPosition={{
				left: 42,
				top: 10,
			}}
			inline={false}
			name={GraphQL.InterfaceHint.Relevance}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(RelevanceHint);
