/* eslint-disable */
import GraphQL from '../../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  cases: ReadonlyArray<GraphQL.IgnorePageIssueCasesOfImageIssuesOnWholeWebsiteCase> | GraphQL.IgnorePageIssueCasesOfImageIssuesOnWholeWebsiteCase;
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCasesOfImageIssuesOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationVariables = GraphQL.Exact<{
  cases: ReadonlyArray<GraphQL.IgnorePageIssueCasesOfLinkIssuesOnWholeWebsiteCase> | GraphQL.IgnorePageIssueCasesOfLinkIssuesOnWholeWebsiteCase;
  issueName: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation = { readonly __typename?: 'Mutation', readonly UnignorePageIssueCasesOfLinkIssuesOnWholeWebsite: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteDocument = gql`
    mutation UnignorePageIssueCasesOfImageIssuesOnWholeWebsite($cases: [IgnorePageIssueCasesOfImageIssuesOnWholeWebsiteCase!]!, $issueName: String!, $websiteId: WebsiteId!) {
  UnignorePageIssueCasesOfImageIssuesOnWholeWebsite(
    cases: $cases
    issueName: $issueName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationVariables>;

/**
 * __useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation, { data, loading, error }] = useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation({
 *   variables: {
 *      cases: // value for 'cases'
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationVariables>(UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteDocument, options);
      }
export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutationVariables>;
export const UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteDocument = gql`
    mutation UnignorePageIssueCasesOfLinkIssuesOnWholeWebsite($cases: [IgnorePageIssueCasesOfLinkIssuesOnWholeWebsiteCase!]!, $issueName: String!, $websiteId: WebsiteId!) {
  UnignorePageIssueCasesOfLinkIssuesOnWholeWebsite(
    cases: $cases
    issueName: $issueName
    websiteId: $websiteId
  ) {
    query {
      ping
    }
  }
}
    `;
export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationFn = Apollo.MutationFunction<UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationVariables>;

/**
 * __useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation__
 *
 * To run a mutation, you first call `useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation, { data, loading, error }] = useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation({
 *   variables: {
 *      cases: // value for 'cases'
 *      issueName: // value for 'issueName'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationVariables>(UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteDocument, options);
      }
export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationHookResult = ReturnType<typeof useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationResult = Apollo.MutationResult<UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation>;
export type UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationOptions = Apollo.BaseMutationOptions<UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation, UnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutationVariables>;