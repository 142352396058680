import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import IgnoringModalBodyLayout from '~/components/atoms/issues/components/modals/IgnoringModalBodyLayout';
import IgnoringStatus, {
	STATE_IGNORED,
	STATE_NOT_IGNORED,
} from '~/components/atoms/issues/components/IgnoringStatus';
import IgnoringTable, {
	type IgnoringTableCellRendererInput,
	type IgnoringTableGetColumnWidthInput,
	type IgnoringTableHeaderCellRendererInput,
} from '~/components/logic/issues/ignoring/IgnoringTable';
import {
	renderEllipsisCell,
} from '~/components/atoms/dataTables/utils/ReactVirtualizedCells';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import {
	useIgnorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation,
	useIgnorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation,
	useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation,
	useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation,
} from './IgnorePageCases.gql';

import useTrackIgnoringUpdateInPendo from '~/hooks/useTrackIgnoringUpdateInPendo';

import {
	loadPageRelations,
} from '~/actions/pages/detail';

import {
	type IssueName,
	PageIssue,
} from '~/model/issuesNew';

import {
	STATE_LOADED,
} from '~/model/pages';

import {
	pageOutboundLinksSelector,
	pageOutgoingInternalLinksSelector,
} from '~/state/pages/currentPageSelectors';



const ELLIPSIS_POPUP_ZINDEX = 2000;

const columnWidths = {
	url: 300,
	scope: 202,
};

const LINKS_FILTERS = {
	[PageIssue.LinksBroken]: (link) => {
		return link.get('ignoredCase') !== undefined || (link.get('status_code') >= 400 && link.get('status_code') < 500);
	},
	[PageIssue.LinksRedirected]: (link) => {
		return link.get('ignoredCase') !== undefined || (link.get('status_code') >= 300 && link.get('status_code') < 400);
	},
	[PageIssue.LinksToCanonicalized]: (link) => {
		return link.get('ignoredCase') !== undefined || (link.get('canonical') && (link.get('id') !== link.get('canonical').get('id')));
	},
};



const messages = defineMessages({
	headingsStatus: {
		id: 'ui.issueDetail.ignoringModal.scope.general.column.status.heading',
	},
	headingsUrl: {
		id: 'ui.issueDetail.ignoringModal.casesTable.general.column.url.heading',
	},
	ignoringStatusIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.ignored',
	},
	ignoringStatusNotIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.notIgnored',
	},
	ignoringStatusChangeWillBeIgnored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeIgnored',
	},
	ignoringStatusChangeWillBeUnignored: {
		id: 'ui.issueDetail.ignoringModal.ignoringStatus.willBeUnignored',
	},
});

const ignoredAlreadyOnPageDescriptionMessages = defineMessages({
	[PageIssue.ImagesAltAttribute]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnPage.description',
	},
	[PageIssue.ImagesMixedTransport]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksBroken]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksRedirected]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
	[PageIssue.LinksToCanonicalized]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnPage.description',
	},
});

const ignoredAlreadyOnWebsiteDescriptionMessages = defineMessages({
	[PageIssue.ImagesAltAttribute]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnWebsite.description',
	},
	[PageIssue.ImagesMixedTransport]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoredAlreadyOnWebsite.description',
	},
	[PageIssue.LinksBroken]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnWebsite.description',
	},
	[PageIssue.LinksRedirected]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnWebsite.description',
	},
	[PageIssue.LinksToCanonicalized]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoredAlreadyOnWebsite.description',
	},
});

const noCasesIgnoredDescriptionMessages = defineMessages({
	[PageIssue.ImagesAltAttribute]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoringInactive.description',
	},
	[PageIssue.ImagesMixedTransport]: {
		id: 'ui.issueDetail.ignoringModal.scope.images.ignoringInactive.description',
	},
	[PageIssue.LinksBroken]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoringInactive.description',
	},
	[PageIssue.LinksRedirected]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoringInactive.description',
	},
	[PageIssue.LinksToCanonicalized]: {
		id: 'ui.issueDetail.ignoringModal.scope.links.ignoringInactive.description',
	},
});

function getColumnWidth({ index }: IgnoringTableGetColumnWidthInput) {
	switch (index) {

		case 0:
			return columnWidths.url;

		case 1:
			return columnWidths.scope;

	}
}

function renderHeader({ columnIndex }: IgnoringTableHeaderCellRendererInput) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsUrl} />
					)}
				/>
			);
			break;
		case 1:
			cellContent = (
				<TableLabel
					label={(
						<FormattedMessage {...messages.headingsStatus} />
					)}
				/>
			);
			break;

	}

	return cellContent;
}

function renderCell({
	columnIndex,
	isChecked,
	item: ignoredCase,
}: IgnoringTableCellRendererInput<{
	id: number,
	isActive: boolean,
	url: string,
}>) {
	let cellContent;

	switch (columnIndex) {

		case 0:
			cellContent = renderURLCell({ ignoredCase });

			break;

		case 1:
			cellContent = renderStatusCell({
				isChecked,
				status: ignoredCase.isActive,
			});

			break;

	}

	return cellContent;
}

function renderStatusCell({ isChecked, status }) {
	const text = status ? (
		<FormattedMessage {...messages.ignoringStatusIgnored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusNotIgnored} />
	);

	const possibleDescription = status ? (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeUnignored} />
	) : (
		<FormattedMessage {...messages.ignoringStatusChangeWillBeIgnored} />
	);

	const message = (
		<UpcomingValueUpdateHighlight updateDescription={isChecked && possibleDescription}>
			<IgnoringStatus state={status ? STATE_IGNORED : STATE_NOT_IGNORED}>
				{text}
			</IgnoringStatus>
		</UpcomingValueUpdateHighlight>
	);

	return renderEllipsisCell(
		message,
		ELLIPSIS_POPUP_ZINDEX,
		isChecked ? possibleDescription : text,
	);
}

function renderURLCell({ ignoredCase }) {
	const displayUrl = (
		<ExternalLink
			ellipsis={true}
			href={ignoredCase.url}
			target="_blank"
		>
			{ignoredCase.url}
		</ExternalLink>
	);

	const displayUrlTooltip = (
		<ExternalLink
			href={ignoredCase.url}
			target="_blank"
		>
			{ignoredCase.url}
		</ExternalLink>
	);

	return renderEllipsisCell(
		displayUrl,
		ELLIPSIS_POPUP_ZINDEX,
		displayUrlTooltip,
	);
}



type Props = {
	issue: {
		context: any,
		ignoringRuleOnCases: {
			ignoredCases: ReadonlyArray<any>,
		},
		ignoringRuleOnPage: {
			isEffective: boolean,
		},
		ignoringRuleOnWebsite: {
			isEffective: boolean,
		},
		name: IssueName,
	},
	legacyUrlId: number,
	reloadCallback: () => Promise<void>,
	websiteId: CK.WebsiteId,
};

const IgnorePageCases: React.FC<Props> = (props) => {
	const {
		issue,
		legacyUrlId,
		reloadCallback,
		websiteId,
	} = props;

	const dispatch = useDispatch();
	const outgoingInternalLinks = useSelector(pageOutgoingInternalLinksSelector);
	const outboundLinks = useSelector(pageOutboundLinksSelector);
	const trackIgnoringUpdateInPendo = useTrackIgnoringUpdateInPendo();

	const [ignorePageIssueCasesOfImageIssuesOnWholeWebsite] = useIgnorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation();
	const [ignorePageIssueCasesOfLinkIssuesOnWholeWebsite] = useIgnorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation();
	const [unignorePageIssueCasesOfImageIssuesOnWholeWebsite] = useUnignorePageIssueCasesOfImageIssuesOnWholeWebsiteMutation();
	const [unignorePageIssueCasesOfLinkIssuesOnWholeWebsite] = useUnignorePageIssueCasesOfLinkIssuesOnWholeWebsiteMutation();

	const issueName = issue.name;

	const cases = React.useMemo(
		() => {
			const ignoredCases = issue.ignoringRuleOnCases.ignoredCases;

			const cases = (() => {
				if (
					issue.name === PageIssue.ImagesAltAttribute
					|| issue.name === PageIssue.ImagesMixedTransport
				) {
					const result: Array<{
						id: number,
						isActive: boolean,
						url: string,
					}> = [];

					const allImageIds: Array<number> = [];

					issue.context.images.forEach((detail) => {
						if (allImageIds.includes(detail.id)) {
							return;
						}

						allImageIds.push(detail.id);

						result.push({
							id: detail.id,
							isActive: ignoredCases.some((ignoredCase) => ignoredCase.id === detail.id),
							url: detail.src,
						});
					});

					ignoredCases.forEach((ignoredCase) => {
						if (allImageIds.includes(ignoredCase.id)) {
							return;
						}

						allImageIds.push(ignoredCase.id);

						result.push({
							id: ignoredCase.id,
							isActive: true,
							url: ignoredCase.url,
						});
					});

					return result;
				}

				if (
					issue.name === PageIssue.LinksBroken
					|| issue.name === PageIssue.LinksRedirected
					|| issue.name === PageIssue.LinksToCanonicalized
				) {
					if (
						!outgoingInternalLinks
						|| outgoingInternalLinks.get('_status') !== STATE_LOADED
						|| !outboundLinks
						|| outboundLinks.get('_status') !== STATE_LOADED
					) {
						return [];
					}

					const allLinks: Array<{
						id: number,
						url: string,
					}> = [];

					const allUrlIds: Array<number> = [];

					outgoingInternalLinks
						.get('data')
						.valueSeq()
						.filter(LINKS_FILTERS[issue.name])
						.forEach((link) => {
							if (allUrlIds.includes(link.get('id'))) {
								return;
							}

							allUrlIds.push(link.get('id'));

							allLinks.push({
								id: link.get('id'),
								url: link.get('url'),
							});
						});

					outboundLinks
						.get('data')
						.valueSeq()
						.filter(LINKS_FILTERS[issue.name])
						.forEach((link) => {
							if (allUrlIds.includes(link.get('url_id'))) {
								return;
							}

							allUrlIds.push(link.get('url_id'));

							allLinks.push({
								id: link.get('url_id'),
								url: link.get('url'),
							});
						});

					ignoredCases.forEach((ignoredCase) => {
						if (allUrlIds.includes(ignoredCase.id)) {
							return;
						}

						allUrlIds.push(ignoredCase.id);

						allLinks.push({
							id: ignoredCase.id,
							url: ignoredCase.url,
						});
					});

					return allLinks.map((link) => ({
						id: link.id,
						isActive: ignoredCases.some((ignoredCase) => ignoredCase.url === link.url),
						url: link.url,
					}));
				}

				throw new Error(
					`Can't find cases for issue '${issue.name}'`,
				);
			})();

			return cases.sort((caseA, caseB) => {
				const ignoredA = caseA.isActive;
				const ignoredB = caseB.isActive;

				if (ignoredA && !ignoredB) {
					return -1;
				}

				if (!ignoredA && ignoredB) {
					return 1;
				}

				return 0;
			});
		},
		[
			issue,
			outboundLinks,
			outgoingInternalLinks,
		],
	);

	React.useEffect(
		() => {
			if (
				issueName === PageIssue.LinksBroken
				|| issueName === PageIssue.LinksRedirected
				|| issueName === PageIssue.LinksToCanonicalized
			) {
				dispatch(loadPageRelations(websiteId, legacyUrlId, 'outgoing_internal_links', 0));
				dispatch(loadPageRelations(websiteId, legacyUrlId, 'outbound_links', 0));
			}
		},
		[
			dispatch,
			issueName,
			legacyUrlId,
			websiteId,
		],
	);

	const handleSubmit = React.useCallback(
		async (selectedRows) => {
			const casesToBeIgnored: Array<{
				url: string,
			}> = [];
			const casesToBeUnignored: Array<{
				url: string,
			}> = [];

			selectedRows.forEach((caseId) => {
				const ignoredCase = cases.find((theCase) => theCase.id === caseId);

				if (ignoredCase === undefined) {
					throw new Error(
						`case must be present in cases`,
					);
				}

				if (ignoredCase.isActive) {
					casesToBeUnignored.push({
						url: ignoredCase.url,
					});
				} else {
					casesToBeIgnored.push({
						url: ignoredCase.url,
					});
				}
			});

			if (casesToBeIgnored.length > 0) {
				if (
					issueName === PageIssue.ImagesAltAttribute
					|| issueName === PageIssue.ImagesMixedTransport
				) {
					await ignorePageIssueCasesOfImageIssuesOnWholeWebsite({
						variables: {
							cases: casesToBeIgnored,
							issueName,
							websiteId,
						},
					});
				} else if (
					issueName === PageIssue.LinksBroken
					|| issueName === PageIssue.LinksRedirected
					|| issueName === PageIssue.LinksToCanonicalized
				) {
					await ignorePageIssueCasesOfLinkIssuesOnWholeWebsite({
						variables: {
							cases: casesToBeIgnored,
							issueName,
							websiteId,
						},
					});
				}

				trackIgnoringUpdateInPendo(
					websiteId,
					issueName,
					true,
					'case',
				);
			}

			if (casesToBeUnignored.length > 0) {
				if (
					issueName === PageIssue.ImagesAltAttribute
					|| issueName === PageIssue.ImagesMixedTransport
				) {
					await unignorePageIssueCasesOfImageIssuesOnWholeWebsite({
						variables: {
							cases: casesToBeUnignored,
							issueName,
							websiteId,
						},
					});
				} else if (
					issueName === PageIssue.LinksBroken
					|| issueName === PageIssue.LinksRedirected
					|| issueName === PageIssue.LinksToCanonicalized
				) {
					await unignorePageIssueCasesOfLinkIssuesOnWholeWebsite({
						variables: {
							cases: casesToBeUnignored,
							issueName,
							websiteId,
						},
					});
				}

				trackIgnoringUpdateInPendo(
					websiteId,
					issueName,
					false,
					'case',
				);
			}

			await reloadCallback();
		},
		[
			cases,
			ignorePageIssueCasesOfImageIssuesOnWholeWebsite,
			ignorePageIssueCasesOfLinkIssuesOnWholeWebsite,
			issueName,
			reloadCallback,
			trackIgnoringUpdateInPendo,
			unignorePageIssueCasesOfImageIssuesOnWholeWebsite,
			unignorePageIssueCasesOfLinkIssuesOnWholeWebsite,
			websiteId,
		],
	);

	if (cases.length === 0) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					<FormattedMessage {...noCasesIgnoredDescriptionMessages[issue.name]} />
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	if (issue.ignoringRuleOnWebsite.isEffective || issue.ignoringRuleOnPage.isEffective) {
		return (
			<IgnoringModalBodyLayout>
				<BlankSlate>
					{issue.ignoringRuleOnWebsite.isEffective ? (
						<FormattedMessage {...ignoredAlreadyOnWebsiteDescriptionMessages[issue.name]} />
					) : (
						<FormattedMessage {...ignoredAlreadyOnPageDescriptionMessages[issue.name]} />
					)}
				</BlankSlate>
			</IgnoringModalBodyLayout>
		);
	}

	return (
		<IgnoringModalBodyLayout>
			<IgnoringTable
				bodyCellRenderer={renderCell}
				columnCount={2}
				columnWidth={getColumnWidth}
				headerCellRenderer={renderHeader}
				items={cases}
				name="cases"
				onSubmitChangesCallback={handleSubmit}
				valueGetter={(ignoredCase) => ignoredCase.id}
			/>
		</IgnoringModalBodyLayout>
	);
};



export default IgnorePageCases;
