import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ContentHeadingsIconBuilder: IconBuilder = {
	icon: ({ color = '#964FA5', size }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Group"
				stroke="none"
				strokeWidth="1"
			>
				<g
					id="base-info"
					transform="translate(1.000000, 1.000000)"
				>
					<polygon
						id="Stroke-27"
						points="43.6381818 47.2727273 14.5472727 47.2727273 14.5472727 10.9090909 43.6381818 10.9090909"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<polygon
						id="Stroke-29"
						points="38.1836364 21.8181818 20.0018182 21.8181818 20.0018182 20 38.1836364 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</polygon>
					<path
						d="M20.0010909,29.0909091 L32.727273,29.0909091"
						id="Stroke-30"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
					<path
						d="M20.0010909,36.3636364 L32.7283636,36.3636364"
						id="Stroke-31"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={(60 / size) * 2}
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 60,
	viewBoxWidth: 60,
};



export default ContentHeadingsIconBuilder;

