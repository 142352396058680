import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



const ConversionsIconBuilder: IconBuilder = {
	icon: ({ color = '#EBB910' }) => {
		return (
			<g
				fill="none"
				fillRule="evenodd"
				id="Page-21"
				stroke="none"
				strokeWidth="1"
			>
				<g id="statistics2-copy">
					<path
						d="M21.0222,26.9201 L21.0222,11.8641 C21.0222,11.3271 21.4572,10.8921 21.9942,10.8921 L25.0512,10.8921 C25.5872,10.8921 26.0232,11.3271 26.0232,11.8641 L26.0232,26.9201 C26.0232,27.4571 25.5872,27.8921 25.0512,27.8921 L21.9942,27.8921 C21.4572,27.8921 21.0222,27.4571 21.0222,26.9201 L21.0222,26.9201 Z"
						id="Stroke-133"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M13.0516,26.8932 L13.0516,5.8922 C13.0516,5.3402 13.4996,4.8922 14.0516,4.8922 L17.0526,4.8922 C17.6046,4.8922 18.0526,5.3402 18.0526,5.8922 L18.0526,26.8932 C18.0526,27.4452 17.6046,27.8932 17.0526,27.8932 L14.0516,27.8932 C13.4996,27.8932 13.0516,27.4452 13.0516,26.8932 L13.0516,26.8932 Z"
						id="Stroke-134"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
					<path
						d="M5.0222,26.8872 L5.0222,16.8932 C5.0222,16.3412 5.4702,15.8932 6.0222,15.8932 L9.0232,15.8932 C9.5752,15.8932 10.0232,16.3412 10.0232,16.8932 L10.0232,26.8872 C10.0232,27.4392 9.5752,27.8872 9.0232,27.8872 L6.0222,27.8872 C5.4702,27.8872 5.0222,27.4392 5.0222,26.8872 L5.0222,26.8872 Z"
						id="Stroke-135"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
					>
					</path>
				</g>
			</g>
		);
	},
	viewBoxHeight: 32,
	viewBoxWidth: 32,
};



export default ConversionsIconBuilder;
