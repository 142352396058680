import GraphQL from '~/types/graphql';

import AdidasLogo from '../../images/customers/adidas.svg';
import AtlassianLogo from '../../images/customers/atlassian.svg';
import AxaLogo from '../../images/customers/axa.svg';
import BlueArrayLogo from '../../images/customers/blue-array.svg';
import CondeNastLogo from '../../images/customers/conde-nast.svg';
import DanoneLogo from '../../images/customers/danone.svg';
import DistilledLogo from '../../images/customers/distilled.svg';
import DpgMediaLogo from '../../images/customers/dpgmedia.svg';
import FedexLogo from '../../images/customers/fedex.svg';
import FutureLogo from '../../images/customers/future.svg';
import GartnerLogo from '../../images/customers/gartner.svg';
import GroupMLogo from '../../images/customers/group-m.svg';
import GrouponLogo from '../../images/customers/groupon.svg';
import HiveDigitalLogo from '../../images/customers/hive-digital.svg';
import HmLogo from '../../images/customers/hm.svg';
import ImpremediaLogo from '../../images/customers/impremedia.svg';
import IprospectLogo from '../../images/customers/iprospect.svg';
import MacmillanLogo from '../../images/customers/macmillan.svg';
import NetflixLogo from '../../images/customers/netflix.svg';
import NortonLogo from '../../images/customers/norton.svg';
import OutspokenMediaLogo from '../../images/customers/outspoken-media.svg';
import RedHatLogo from '../../images/customers/red-hat.svg';
import RiseAtSevenLogo from '../../images/customers/rise-at-seven.svg';
import SanomaLogo from '../../images/customers/sanoma.svg';
import SeerInteractiveLogo from '../../images/customers/seer-interactive.svg';
import ShopifyLogo from '../../images/customers/shopify.svg';
import SurveyMonkeyLogo from '../../images/customers/survey-monkey.svg';
import TerakeetLogo from '../../images/customers/terakeet.svg';
import TheNewYorkerLogo from '../../images/customers/the-new-yorker.svg';
import TransferwiseLogo from '../../images/customers/transferwise.svg';
import TrustedMediaBrandsLogo from '../../images/customers/trusted-media-brands.svg';
import UnicefLogo from '../../images/customers/unicef.svg';
import VogueLogo from '../../images/customers/vogue.svg';
import WileyLogo from '../../images/customers/wiley.svg';
import WundermanThompsonLogo from '../../images/customers/wunderman-thompson.svg';



export enum Screen {
	PricingScreen = 'pricing-screen',
	SignUpModal = 'sign-up-modal',
	TrialExpiredModal = 'trial-expired-modal',
	TrialRevivalModal = 'trial-revival-modal',
	Other = 'other',
}



type Customer = {
	name: string,
	logo: string,
};

// Only allow keys within `Screen` and `CustomerType`. Each combination of these
// is defined as optional _except_ for `Screen.Other` and `CustomerType.Other`
// which is required.
type SatisfiedCustomersLogos = Partial<Record<Exclude<Screen, Screen.Other>, Partial<Record<GraphQL.CustomerType, Groupings>>>> & {
	[Screen.Other]: Partial<Record<Exclude<GraphQL.CustomerType, GraphQL.CustomerType.Other>, Groupings>> & {
		[GraphQL.CustomerType.Other]: Groupings,
	},
};

type Groupings = Array<
	Array<Customer>
>;



const customers = {
	'adidas': {
		name: 'Adidas',
		logo: AdidasLogo,
	},
	'atlassian': {
		name: 'Atlassian',
		logo: AtlassianLogo,
	},
	'axa': {
		name: 'Axa',
		logo: AxaLogo,
	},
	'blue-array': {
		name: 'Blue Array',
		logo: BlueArrayLogo,
	},
	'conde-nast': {
		name: 'Condé Nast',
		logo: CondeNastLogo,
	},
	'danone': {
		name: 'Danone',
		logo: DanoneLogo,
	},
	'distilled': {
		name: 'Distilled',
		logo: DistilledLogo,
	},
	'dpg-media': {
		name: 'DPG Media',
		logo: DpgMediaLogo,
	},
	'fedex': {
		name: 'FedEx',
		logo: FedexLogo,
	},
	'future': {
		name: 'Future',
		logo: FutureLogo,
	},
	'gartner': {
		name: 'Gartner',
		logo: GartnerLogo,
	},
	'group-m': {
		name: 'GroupM',
		logo: GroupMLogo,
	},
	'groupon': {
		name: 'Groupon',
		logo: GrouponLogo,
	},
	'hive-digital': {
		name: 'Hive Digital',
		logo: HiveDigitalLogo,
	},
	'hm': {
		name: 'H&M',
		logo: HmLogo,
	},
	'impremedia': {
		name: 'Impremedia',
		logo: ImpremediaLogo,
	},
	'iprospect': {
		name: 'iProspect',
		logo: IprospectLogo,
	},
	'macmillan': {
		name: 'Macmillan',
		logo: MacmillanLogo,
	},
	'netflix': {
		name: 'Netflix',
		logo: NetflixLogo,
	},
	'norton': {
		name: 'Norton',
		logo: NortonLogo,
	},
	'outspoken-media': {
		name: 'Outspoken Media',
		logo: OutspokenMediaLogo,
	},
	'red-hat': {
		name: 'Red Hat',
		logo: RedHatLogo,
	},
	'rise-at-seven': {
		name: 'Rise at Seven',
		logo: RiseAtSevenLogo,
	},
	'sanoma': {
		name: 'Sanoma',
		logo: SanomaLogo,
	},
	'seer-interactive': {
		name: 'Seer Interactive',
		logo: SeerInteractiveLogo,
	},
	'shopify': {
		name: 'Shopify',
		logo: ShopifyLogo,
	},
	'survey-monkey': {
		name: 'Survey Monkey',
		logo: SurveyMonkeyLogo,
	},
	'terakeet': {
		name: 'Terakeet',
		logo: TerakeetLogo,
	},
	'the-new-yorker': {
		name: 'The New Yorker',
		logo: TheNewYorkerLogo,
	},
	'transferwise': {
		name: 'Transferwise',
		logo: TransferwiseLogo,
	},
	'trusted-media-brands': {
		name: 'Trusted Media Brands',
		logo: TrustedMediaBrandsLogo,
	},
	'unicef': {
		name: 'UNICEF',
		logo: UnicefLogo,
	},
	'vogue': {
		name: 'Vogue',
		logo: VogueLogo,
	},
	'wiley': {
		name: 'Wiley',
		logo: WileyLogo,
	},
	'wunderman-thompson': {
		name: 'Wunderman Thompson',
		logo: WundermanThompsonLogo,
	},
};



// Only allow keys within `Screen` and `CustomerType`. Each combination of these
// is defined as optional _except_ for `Screen.Other` and `CustomerType.Other`
// which is required.
const satisfiedCustomerLogos: SatisfiedCustomersLogos = {
	[Screen.Other]: {
		[GraphQL.CustomerType.Agency]: [
			[
				customers['iprospect'],
				customers['group-m'],
				customers['seer-interactive'],
			],
			[
				customers['blue-array'],
				customers['distilled'],
				customers['terakeet'],
			],
		],
		[GraphQL.CustomerType.Publisher]: [
			[
				customers['netflix'],
				customers['conde-nast'],
				customers['the-new-yorker'],
			],
			[
				customers['hm'],
				customers['sanoma'],
				customers['macmillan'],
			],
			[
				customers['adidas'],
				customers['dpg-media'],
				customers['impremedia'],
			],
		],
		[GraphQL.CustomerType.Other]: [
			[
				customers['netflix'],
				customers['fedex'],
				customers['conde-nast'],
			],
			[
				customers['adidas'],
				customers['unicef'],
				customers['shopify'],
			],
			[
				customers['vogue'],
				customers['axa'],
				customers['groupon'],
			],
		],
	},
	[Screen.SignUpModal]: {
		[GraphQL.CustomerType.Agency]: [
			[
				customers['iprospect'],
				customers['group-m'],
				customers['seer-interactive'],
			],
		],
		[GraphQL.CustomerType.Publisher]: [
			[
				customers['netflix'],
				customers['conde-nast'],
				customers['the-new-yorker'],
			],
		],
		[GraphQL.CustomerType.Other]: [
			[
				customers['netflix'],
				customers['fedex'],
				customers['conde-nast'],
			],
		],
	},
	[Screen.PricingScreen]: {
		[GraphQL.CustomerType.Agency]: [
			[
				customers['iprospect'],
				customers['group-m'],
				customers['seer-interactive'],
			],
		],
		[GraphQL.CustomerType.Publisher]: [
			[
				customers['netflix'],
				customers['conde-nast'],
				customers['the-new-yorker'],
			],
		],
		[GraphQL.CustomerType.Other]: [
			[
				customers['netflix'],
				customers['fedex'],
				customers['conde-nast'],
			],
		],
	},
};



export function listSatisfiedCustomerLogos(
	customerType?: GraphQL.CustomerType | null,
	screen?: Screen | null,
): Groupings {
	customerType = customerType || GraphQL.CustomerType.Other;
	screen = screen || Screen.Other;

	const selectedScreenCustomers = satisfiedCustomerLogos[screen];

	// defined `screen` & defined `customerType`
	if (selectedScreenCustomers && customerType in selectedScreenCustomers) {
		return selectedScreenCustomers[customerType] as Groupings;
	}

	// fallback `screen` & defined `customerType`
	if (customerType in satisfiedCustomerLogos[Screen.Other]) {
		return satisfiedCustomerLogos[Screen.Other][customerType] as Groupings;
	}

	// defined `screen` & fallback `customerType`
	if (selectedScreenCustomers && GraphQL.CustomerType.Other in selectedScreenCustomers) {
		return selectedScreenCustomers[GraphQL.CustomerType.Other] as Groupings;
	}

	// fallback `screen` & fallback `customerType`
	return satisfiedCustomerLogos[Screen.Other][GraphQL.CustomerType.Other];
}
