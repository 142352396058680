import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import DotInterfaceHint from '~/components/logic/DotInterfaceHint';
import {
	UiInteraction,
} from '~/hooks/useUiInteractions';



const messages = defineMessages({
	message: {
		id: 'ui.interfaceHints.hint.columnSetsPicker',
	},
});



type Props = {
	children: React.ReactNode,
};

const ColumnSetsPickerHint: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<DotInterfaceHint
			conditionsAlready={[
				({ uiInteractions }) => uiInteractions[UiInteraction.PagesOverviewOpen] >= 4,
			]}
			conditionsNotAnymore={[
				({ uiInteractions }) => uiInteractions[UiInteraction.ColumnsSetsListOpen] === 0,
			]}
			content={(
				<FormattedMessage {...messages.message} />
			)}
			iconPosition={{
				left: 55,
				bottom: 2,
			}}
			name={GraphQL.InterfaceHint.ColumnSetsPicker}
		>
			{children}
		</DotInterfaceHint>
	);
};



export default React.memo(ColumnSetsPickerHint);
